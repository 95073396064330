import * as React from "react";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Client } from "../../../api/graphQL/queryModels";

interface ClientFieldProps {
    options: Client[];
    selectedId: number | null;
    onChange: (selectedId: number | null) => unknown;
    loading: boolean;
}

const ClientField = (props: ClientFieldProps) => {
    const { options, selectedId, loading } = props;

    return (
        <FormControl variant="outlined" fullWidth>
            <Autocomplete
                loading={loading}
                value={selectedId && options.length ? selectedId : null}
                options={options.map((o) => o.id)}
                getOptionLabel={(value) => {
                    const option = options.find((p) => p.id === value);
                    return option ? option.name : ""; // Use a fallback value if 'option' is undefined
                }}
                onChange={(event: unknown, newValue: number | null) => {
                    props.onChange(newValue || null);
                }}
                fullWidth
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Client" placeholder="Client" size="small" fullWidth />
                )}
            />
        </FormControl>
    );
};

export default ClientField;
