import * as React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FilterIcon from "@material-ui/icons/FilterList";
import { Avatar, Chip, Grid, Typography } from "@material-ui/core";
import { BeaconItemTag } from "../../../api/graphQL/queryModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex"
        },
        popper: {
            zIndex: 1
        },
        filterContent: {
            width: 500,
            maxWidth: "100%",
            padding: theme.spacing(2),
            "& > *": {
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1)
            }
        },
        numFilters: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.type === "dark" ? theme.palette.offCommon.black : theme.palette.common.white,
            width: 20,
            height: 20,
            fontSize: "0.875rem"
        },
        chipLabel: {
            color: theme.palette.text.primary
        },
        chipLabelSelected: {
            color: theme.palette.type === "dark" ? theme.palette.offCommon.black : theme.palette.common.white
        },
        chipSelected: {
            "&:focus": {
                backgroundColor: theme.palette.type === "dark" ? "#6CD9CA" : theme.palette.primary.dark
            }
        },
        chip: {
            "&:focus": {
                backgroundColor: theme.palette.type === "dark" ? "#464646" : "#dfdfdf"
            }
        }
    })
);

const TagFilter = (props: { tags: BeaconItemTag[]; onChange: (tags: BeaconItemTag[]) => unknown }) => {
    const { tags, onChange } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const [selectedTags, setSelectedTags] = React.useState<BeaconItemTag[]>([]);
    const toggleTag = (tag: BeaconItemTag) => {
        const newValue = selectedTags.some((t) => t.id === tag.id) ? selectedTags.filter((t) => t.id !== tag.id) : selectedTags.concat(tag);
        setSelectedTags(newValue);
        onChange(newValue);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const resetFilter = () => {
        setSelectedTags([]);
        onChange([]);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const sortedTags = tags.slice().sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className={classes.root}>
            <div>
                <Grid container alignItems="center">
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        startIcon={<FilterIcon />}
                    >
                        Tags
                    </Button>
                    {selectedTags.length ? <Avatar className={classes.numFilters}>{`${selectedTags.length}`}</Avatar> : null}
                </Grid>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div className={classes.filterContent}>
                                        {sortedTags.map((tag) => {
                                            return (
                                                <Chip
                                                    key={tag.id}
                                                    color={selectedTags.some((t) => t.id === tag.id) ? "primary" : "default"}
                                                    label={
                                                        <Typography
                                                            variant="subtitle2"
                                                            className={
                                                                selectedTags.some((t) => t.id === tag.id)
                                                                    ? classes.chipLabelSelected
                                                                    : classes.chipLabel
                                                            }
                                                        >
                                                            {tag.name}
                                                        </Typography>
                                                    }
                                                    clickable
                                                    onClick={() => toggleTag(tag)}
                                                    className={
                                                        selectedTags.some((t) => t.id === tag.id) ? classes.chipSelected : classes.chip
                                                    }
                                                />
                                            );
                                        })}
                                        {!!selectedTags.length && <Button onClick={resetFilter}>Clear</Button>}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
};

export default React.memo(TagFilter);
