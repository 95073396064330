import { gql } from "@apollo/client";
import { BEACON_ITEM_FIELDS_FRAGMENT, BEACON_STORY_CORE_FIELDS_FRAGMENT } from "./fragments";

export const GET_BEACON_STORIES = gql`
    ${BEACON_STORY_CORE_FIELDS_FRAGMENT}
    query getBeaconStories($first: Int!, $after: String) {
        beaconStories(first: $first, after: $after) {
            edges {
                node {
                    ...BeaconStoryCoreFields
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const GET_BEACON_STORY = gql`
    ${BEACON_STORY_CORE_FIELDS_FRAGMENT}
    query GetBeaconStory($id: String) {
        beaconStory(id: $id) {
            ...BeaconStoryCoreFields
            commentList {
                id
                comments {
                    id
                    author {
                        id
                        givenName
                        surname
                        emailAddress
                        photos {
                            uri
                            size
                        }
                    }
                    text
                }
            }
        }
    }
`;

export const GET_BEACON_TAGS = gql`
    {
        beaconTags(first: 500) {
            edges {
                node {
                    id
                    name
                    isDeleted
                }
            }
        }
    }
`;

export const GET_BEACON_ITEM_RESUME_GROUPS = gql`
    {
        beaconItemResumeGroups(first: 500) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_BEACON_ITEMS_FOR_MAPPING = gql`
    query GetBeaconItemsMinimal($first: Int, $after: String, $categories: [BeaconItemCategory!], $tagIds: [String!]) {
        beaconItems(first: $first, after: $after, categories: $categories, tagIds: $tagIds) {
            edges {
                node {
                    id
                    name
                    resumeGroup {
                        id
                        name
                    }
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const GET_BEACON_ITEMS = gql`
    ${BEACON_ITEM_FIELDS_FRAGMENT}
    query GetBeaconItems($first: Int, $after: String, $categories: [BeaconItemCategory!], $tagIds: [String!]) {
        beaconItems(first: $first, after: $after, categories: $categories, tagIds: $tagIds) {
            edges {
                node {
                    ...BeaconItemCoreFields
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

export const GET_BEACON_ITEM = gql`
    ${BEACON_ITEM_FIELDS_FRAGMENT}
    query GetBeaconItem($id: String) {
        beaconItem(id: $id) {
            ...BeaconItemCoreFields
            stories {
                id
                title
                selectedItems {
                    selectedItem {
                        id
                    }
                    outcome {
                        justification
                        sentiment
                    }
                }
            }
            skilledPeople {
                id
                person {
                    id
                    givenName
                    surname
                    emailAddress
                    photos {
                        uri
                        size
                    }
                }
                isLoved
            }
        }
    }
`;

export const GET_ALL_PEOPLE = gql`
    query GetPeople {
        people {
            id
            givenName
            surname
            jobTitle
            fullName
            emailAddress
            phoneNumber
            officeLocation
            isContractor
            isEmployee
            photos {
                uri
                size
            }
        }
    }
`;

export const SEARCH_BEACON_CONTENT = gql`
    query SearchBeaconContent($query: String!) {
        beaconContentSearch(query: $query) {
            searchId
            results {
                id
                type
                title
                isArchived
                previewDescription
                description
                itemTagNames
                storyLinkedItemNames
                highlights {
                    matchingFieldName
                    matchingTextFragments
                }
            }
            totalResultsCount
        }
    }
`;

export const GET_CLIENTS = gql`
    query GetClients {
        clients(first: 800) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_CLIENT_PROJECTS = gql`
    query GetClientProjects($clientId: Int!) {
        projects(first: 100, clientId: $clientId) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_BEACON_IMAGE_UPLOAD_TOKEN = gql`
    query GetBeaconImageUploadToken($filename: String!) {
        beaconImageUploadToken(filename: $filename) {
            uniqueFilename
            fileUrl
            sasToken
        }
    }
`;

export const GET_BEACON_ITEMS_DATA_QUALITY = gql`
    query GetItemsDataQualityReport($first: Int, $after: String) {
        dataQuality {
            beaconItems(first: $first, after: $after) {
                edges {
                    node {
                        beaconItem {
                            id
                            name
                            author {
                                id
                                givenName
                                surname
                                photos {
                                    uri
                                    size
                                }
                            }
                        }
                        overallScore
                        fields {
                            fieldName
                            score
                            violations {
                                description
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export const GET_BEACON_STORIES_DATA_QUALITY = gql`
    query GetStoriesDataQualityReport($first: Int, $after: String) {
        dataQuality {
            beaconStories(first: $first, after: $after) {
                edges {
                    node {
                        beaconStory {
                            id
                            title
                        }
                        overallScore
                        fields {
                            fieldName
                            score
                            violations {
                                description
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;
