import axios from "axios";

export interface UriSettings {
    myApp: string;
    peopleApp: string;
    projectsApp: string;
    beaconApp: string;
    estimatesApp: string;
    surveysApp: string;
    secretShareApp: string;
}

export interface AzureAdSettings {
    authority: string;
    clientId: string;
    scopes: string;
    validIssuer: string;
}

export interface ApplicationSettings {
    appUrl: string;
    apiUrl: string;
    appInsightsKey: string;
    authSettings: AzureAdSettings;
    relatedAppsUri: UriSettings;
}

export interface ApplicationContext {
    settings: ApplicationSettings;
}

class AppContextApi {
    public getApplicationContext = () => axios.get<ApplicationContext>(`/api/applicationContext`);
}
const AppContextApiInstance = new AppContextApi();

export default AppContextApiInstance;
