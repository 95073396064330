import * as React from "react";
import { Typography } from "@material-ui/core";
import { BeaconItemCategory } from "../../../api/graphQL/queryModels";

interface CategoryProps {
    category: BeaconItemCategory;
}

const Category = (props: CategoryProps) => {
    const { category } = props;
    const text = category === BeaconItemCategory.None ? BeaconItemCategory.Technology : category;
    const formattedText = text.charAt(0) + text.slice(1).toLowerCase();

    return (
        <Typography variant="body1" className={`beacon-category ${text}`}>
            Category: <b>{formattedText}</b>
        </Typography>
    );
};

export default Category;
