import * as React from "react";
import { Grid } from "@material-ui/core";

interface FormFieldProps {
    fullWidth?: boolean;
    children: React.ReactNode;
}

const FormField = (props: FormFieldProps) => {
    return (
        <Grid item xs={12} className="form-field">
            <Grid container>
                <Grid item xs={12} sm={props.fullWidth ? 12 : 8} md={props.fullWidth ? 12 : 6}>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FormField;
