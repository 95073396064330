import { Person } from "../../../api/graphQL/queryModels";
import React from "react";
import { Avatar, Badge, Link, makeStyles, Typography } from "@material-ui/core";
import { Formatter } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "inline-flex"
    },
    author: {
        width: 40,
        height: 40
    },
    text: {
        paddingLeft: theme.spacing(1)
    }
}));

const Author: React.FunctionComponent<{
    author: Person;
    createDate: Date | string | null;
}> = (props) => {
    const classes = useStyles();
    const { author, createDate } = props;
    return (
        <div className={classes.container}>
            <Link href={`https://people.devfacto.com/people/${author.id}`} target="_blank">
                <Badge
                    key={author.id}
                    overlap="circle"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                >
                    <Avatar
                        className={classes.author}
                        src={author.photos && author.photos.length ? author.photos[0].uri : "/img/no_photo.png"}
                    ></Avatar>
                </Badge>
            </Link>
            <div className={classes.text}>
                <Typography variant="body2">{`${author.givenName} ${author.surname}`}</Typography>
                {createDate && (
                    <Typography variant="caption" component="p" color="textSecondary">{`Created ${Formatter.formatLocalDate(
                        createDate
                    )}`}</Typography>
                )}
            </div>
        </div>
    );
};

export default Author;
