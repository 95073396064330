import * as React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { BeaconItem } from "../../../api/graphQL/queryModels";
import { useQuery, useMutation } from "@apollo/client";
import { GET_BEACON_ITEM } from "../../../api/graphQL/queries";
import { useUserContext } from "../auth/UserContextProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import { BeaconItemInput } from "../../../api/graphQL/mutationModels";
import { useNotifications } from "../notifications/NotificationContextProvider";
import { UPSERT_BEACON_ITEM } from "../../../api/graphQL/mutations";

interface ItemDetailsProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArchiveItem = (props: ItemDetailsProps) => {
    const { isOpen, setIsOpen } = props;
    const { setNotification } = useNotifications();
    const { user: currentUser, isContentAdmin } = useUserContext();
    const { id } = useParams<{ id: string }>();

    const { data } = useQuery<{ beaconItem: BeaconItem }>(GET_BEACON_ITEM, {
        skip: !id,
        variables: {
            id
        }
    });
    const item = data?.beaconItem;
    const archiveValue = !item?.isArchived;
    const canEdit = (!!currentUser?.id && item?.author?.id === currentUser.id) || isContentAdmin;

    const [upsertBeaconItem] = useMutation<{ beaconItemUpsert: BeaconItemInput }>(UPSERT_BEACON_ITEM);

    const handleSave = () => {
        setIsOpen(false);
        saveItem(itemInput);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const itemInput = item
        ? ({
              id: item.id,
              name: item.name,
              author: {
                  id: item.author?.id || currentUser?.id
              },
              category: item.category,
              difficulty: item.difficulty,
              dateCreated: item.dateCreated,
              description: item.description,
              duration: item.duration,
              endorsement: item.endorsement,
              isDeleted: item.isDeleted,
              isArchived: archiveValue,
              people: item.people,
              preparation: item.preparation,
              previewDescription: item.previewDescription,
              tagIds: item.tags?.map((t) => t.id) || [],
              resumeGroupId: item.resumeGroup?.id
          } as BeaconItemInput)
        : null;

    const saveItem = async (beaconItem: BeaconItemInput | null) => {
        {
            !item?.isArchived ? setNotification("Item Archived", "success") : setNotification("Item Unarchived", "success");
        }

        await upsertBeaconItem({
            variables: {
                beaconItem
            }
        });
    };

    return (
        <div>
            <Dialog open={isOpen} aria-labelledby="alert-dialog-title">
                <DialogTitle id="dialog-title">{!item?.isArchived ? "Archive" : "Unarchive"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        {!item?.isArchived
                            ? "Are you sure you want to archive this item?"
                            : "Are you sure you want to unarchive this item?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {canEdit ? (
                        <Button onClick={handleClose} variant="outlined">
                            No, go back
                        </Button>
                    ) : null}
                    <Button onClick={handleSave} variant="contained" component={RouterLink} to={`/?view=items`}>
                        {!item?.isArchived ? "Yes, archive" : "Yes unarchive"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ArchiveItem;
