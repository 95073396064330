import * as React from "react";

import ItemOutcome from "./ItemOutcome";
import { BeaconStory, BeaconItem } from "../../../api/graphQL/queryModels";
import { Grid } from "@material-ui/core";

interface ComponentProps {
    item: BeaconItem;
    linkedStories: BeaconStory[];
}

const ItemOutcomes = (props: ComponentProps) => {
    const { linkedStories, item } = props;

    if (!linkedStories) {
        return null;
    }
    return (
        <Grid container spacing={1}>
            {linkedStories.map((s) => {
                const selectedItem = s.selectedItems.find((i) => i.selectedItem.id === item.id);
                return selectedItem ? (
                    <Grid item xs={12}>
                        <ItemOutcome key={s.id} story={s} selectedItem={selectedItem} />
                    </Grid>
                ) : null;
            })}
        </Grid>
    );
};

export default ItemOutcomes;
