import { gql } from "@apollo/client";

export const BEACON_ITEM_FIELDS_FRAGMENT = gql`
    fragment BeaconItemCoreFields on BeaconItem {
        id
        name
        category
        tags {
            id
            name
        }
        endorsement
        people
        preparation
        duration
        previewDescription
        description
        difficulty
        author {
            id
            givenName
            surname
            isEnabled
            photos {
                uri
                size
            }
        }
        dateCreated
        resumeGroup {
            id
            name
        }
        isDeleted
        isArchived
    }
`;

export const PROJECT_CORE_FIELDS_FRAGMENT = gql`
    fragment ProjectCoreFields on Project {
        id
        name
        clients {
            id
            name
        }
        participants {
            id
            contributedTimeInMinutes
            lastTimeEntryDate
            person {
                id
                givenName
                surname
                emailAddress
                isEnabled
                photos {
                    uri
                    size
                }
            }
        }
        studios {
            id
            name
        }
    }
`;

export const BEACON_STORY_CORE_FIELDS_FRAGMENT = gql`
    ${PROJECT_CORE_FIELDS_FRAGMENT}
    fragment BeaconStoryCoreFields on BeaconStory {
        id
        title
        author {
            id
            givenName
            surname
            emailAddress
            photos {
                uri
                size
            }
        }
        dateCreated
        datePublished
        description
        headerImageUrl
        images {
            filename
            description
            url
            optimizedUrl
            showInGallery
            showInHeader
            thumbnailUrl
            title
        }
        isPublished
        isArchived
        outcome {
            accomplishmentSentiment
            clientSentiment
            difficultySentiment
            failureNotes
            improvementNotes
            stressLevelSentiment
            successNotes
            teamCohesivenessSentiment
        }
        project {
            ...ProjectCoreFields
        }
        previewDescription
        projectStatus
        selectedItems {
            consideredAlternativeItems {
                category
                endorsement
                id
                isDeleted
                name
                tags {
                    id
                    isDeleted
                    name
                }
            }
            explanation
            outcome {
                justification
                sentiment
            }
            selectedItem {
                category
                endorsement
                id
                isDeleted
                name
                tags {
                    id
                    isDeleted
                    name
                }
            }
            title
        }
    }
`;
