import * as React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardPage from "./pages/DashboardPage";

import BeaconDetailPage from "./pages/ItemDetailPage";
import StoryDetailPage from "./pages/StoryDetailPage";
import ItemEditorPage from "./pages/ItemEditorPage";
import StoryEditorPage from "./pages/StoryEditorPage";
import TagEditorPage from "./pages/TagEditorPage";
import DataQualityPage from "./pages/DataQualityPage";

import AuthCompletePage from "./pages/AuthCompletePage";
import AuthenticatedView from "./pages/components/auth/AuthenticatedView";
import ResumeGroupsPage from "./pages/ResumeGroupsPage";

const RouteList: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <AuthenticatedView>
                        <DashboardPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/resume-groups"
                element={
                    <AuthenticatedView>
                        <ResumeGroupsPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/stories/new"
                element={
                    <AuthenticatedView>
                        <StoryEditorPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/stories/:id/edit"
                element={
                    <AuthenticatedView>
                        <StoryEditorPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/stories/:id"
                element={
                    <AuthenticatedView>
                        <StoryDetailPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/items/new"
                element={
                    <AuthenticatedView>
                        <ItemEditorPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/items/:id/edit"
                element={
                    <AuthenticatedView>
                        <ItemEditorPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/items/:id"
                element={
                    <AuthenticatedView>
                        <BeaconDetailPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/tags"
                element={
                    <AuthenticatedView>
                        <TagEditorPage />
                    </AuthenticatedView>
                }
            />
            <Route
                path="/data-quality"
                element={
                    <AuthenticatedView>
                        <DataQualityPage />
                    </AuthenticatedView>
                }
            />
            <Route path="/authComplete" element={AuthCompletePage} />
        </Routes>
    );
};

export default RouteList;
