import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";

import BeaconListItem from "./BeaconListItem";
import { BeaconItem } from "../../../api/graphQL/queryModels";

interface ItemListProps {
    items: BeaconItem[];
    loading: boolean;
}

const ItemList = (props: ItemListProps) => {
    const { items, loading } = props;

    return (
        <div className="beacon-list">
            <Box className="endorsement-list">
                <Grid container spacing={2}>
                    {items.map((beaconItem) => (
                        <Grid item key={beaconItem.id} xs={12} sm={6} md={4} lg={3}>
                            <BeaconListItem beacon={beaconItem} />
                        </Grid>
                    ))}
                    {items.length === 0 && !loading ? (
                        <Grid item xs={12}>
                            <Typography>No items found matching current filter</Typography>{" "}
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </div>
    );
};

export default ItemList;
