import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, Typography, CardActionArea, makeStyles, CardMedia } from "@material-ui/core";

import { BeaconStory, ProjectParticipant } from "../../../api/graphQL/queryModels";
import { PersonList } from "../people";
import { ParticipantSorter } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345
    },
    draftMarker: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: theme.spacing(1),
        background: theme.palette.primary.dark,
        borderBottomLeftRadius: 8,
        color: theme.palette.getContrastText(theme.palette.primary.dark)
    },
    archiveMarker: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: theme.spacing(1),
        background: theme.palette.error.main,
        borderBottomLeftRadius: 8,
        color: theme.palette.common.white
    },
    media: {
        height: "9.25rem"
    },
    content: {
        height: "9.25rem"
    },
    clamp: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical"
    },
    storyCard: {
        height: "18.5rem",
        width: "25.75rem",
        margin: theme.spacing(1)
    }
}));

interface StoryListItemProps {
    story: BeaconStory;
}

const StoryListItem = (props: StoryListItemProps) => {
    const classes = useStyles();
    const { story } = props;
    const participants: ProjectParticipant[] = ParticipantSorter.filterAndSortProjectParticipants(story.project?.participants || []);

    return (
        <Card className={classes.storyCard}>
            <CardActionArea component={RouterLink} to={`/stories/${story.id}`}>
                {!story.isPublished && <div className={classes.draftMarker}>DRAFT</div>}
                {story.isArchived && <div className={classes.archiveMarker}>Archived</div>}
                <CardMedia
                    className={classes.media}
                    component="img"
                    image={story.headerImageUrl ?? "/img/no_header.jpg"}
                    title=""
                    onError={(e: React.ChangeEvent<HTMLImageElement>) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = "/img/no_header.jpg";
                    }}
                />
                <CardContent className={classes.content}>
                    <Typography variant="h6" className={classes.clamp}>
                        {story.title}
                    </Typography>
                    <Typography gutterBottom variant="caption" color="textSecondary" className={classes.clamp}>
                        {story.project?.studios.map((s) => s.name).join(" • ") || "\u00a0"}
                    </Typography>
                    <Typography gutterBottom variant="body1" color="textPrimary" className={classes.clamp}>
                        {story.previewDescription}
                    </Typography>
                    {!!participants.length && (
                        <PersonList
                            people={participants.map((p) => p.person)}
                            size="md"
                            showInactivePeople
                            showText={false}
                            truncate={true}
                        />
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default StoryListItem;
