import * as React from "react";
import { nameof } from "ts-simple-nameof";
import { FormControl, FormField, FormSection } from "../forms";
import { BeaconStoryInput } from "../../../api/graphQL/mutationModels";
import { Client, GraphQlConnection, Project } from "../../../api/graphQL/queryModels";
import ClientField from "../misc/ClientField";
import { useQuery } from "@apollo/client";
import { GET_CLIENTS, GET_CLIENT_PROJECTS } from "../../../api/graphQL/queries";
import ProjectField from "../misc/ProjectField";
import { useNotifications } from "../notifications/NotificationContextProvider";

interface StoryEditorProjectSectionProps {
    story: BeaconStoryInput;
    clientId: number | null;
    setFieldValue: (fieldName: string, fieldValue: unknown) => unknown;
    handleChange: (
        eventOrPath: string | React.ChangeEvent<object>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<object>) => void);
}

const StoryEditorProjectSection = (props: StoryEditorProjectSectionProps) => {
    const { story } = props;
    const { setNotification } = useNotifications();
    const [clientId, setClientId] = React.useState<number | null>(props.clientId);

    const {
        data: clientsData,
        loading: clientsLoading,
        error: clientsError
    } = useQuery<{ clients: GraphQlConnection<Client> }>(GET_CLIENTS);
    const clients = clientsData?.clients.edges.map((e) => e.node) || [];

    const {
        data: projectsData,
        loading: projectsLoading,
        error: projectsError
    } = useQuery<{ projects: GraphQlConnection<Project> }>(GET_CLIENT_PROJECTS, {
        skip: !clientId,
        variables: {
            clientId: clientId
        }
    });
    const projects = projectsData?.projects.edges.map((e) => e.node) || [];

    React.useEffect(() => {
        if (clientsError?.message || projectsError?.message) {
            setNotification(clientsError?.message || projectsError?.message, "error");
        }
    }, [clientsError, projectsError, setNotification]);

    return (
        <FormSection title="Project" fullWidth>
            <FormField>
                <FormControl>
                    <ClientField
                        loading={clientsLoading}
                        selectedId={clientId}
                        options={clients}
                        onChange={(value) => {
                            setClientId(value);
                            props.setFieldValue(
                                nameof<BeaconStoryInput>((s) => s.projectId),
                                null
                            );
                        }}
                    />
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <ProjectField
                        loading={projectsLoading}
                        selectedId={story.projectId}
                        disabled={!clientId}
                        options={projects}
                        onChange={(value) => {
                            props.setFieldValue(
                                nameof<BeaconStoryInput>((s) => s.projectId),
                                value
                            );
                        }}
                    />
                </FormControl>
            </FormField>
        </FormSection>
    );
};

export default StoryEditorProjectSection;
