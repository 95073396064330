import * as React from "react";
import { Tabs, Tab } from "@material-ui/core";
import DocumentTitle from "./components/DocumentTitle";
import ItemsDataQualityView from "./components/dataQuality/ItemsDataQualityView";
import StoriesDataQualityView from "./components/dataQuality/StoriesDataQualityView";

const DataQualityPage = () => {
    const [tab, setTab] = React.useState("stories");

    const handleChange = (event: React.ChangeEvent<object>, newValue: string) => {
        setTab(newValue);
    };

    return (
        <div className="data-quality-page">
            <DocumentTitle pageTitle="Data Quality" />
            <Tabs value={tab} onChange={handleChange}>
                <Tab value={"stories"} label="Stories" />
                <Tab value={"items"} label="Items" />
            </Tabs>
            {tab === "items" && <ItemsDataQualityView />}
            {tab === "stories" && <StoriesDataQualityView />}
        </div>
    );
};

export default DataQualityPage;
