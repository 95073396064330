import * as React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { nameof } from "ts-simple-nameof";
import { Link as RouterLink } from "react-router-dom";
import { Form } from "../forms";
import StoryEditorAboutSection from "./StoryEditorAboutSection";
import StoryEditorOutcomesSection from "./StoryEditorOutcomesSection";
import StoryEditorLinkedItemsSection from "./StoryEditorLinkedItemsSection";
import StoryEditorProjectSection from "./StoryEditorProjectSection";
import StoryEditorImagesSection from "./StoryEditorImagesSection";
import StoryEditorPublishSection from "./StoryEditorPublishSection";
import { BeaconStoryInput } from "../../../api/graphQL/mutationModels";
import { BeaconItem } from "../../../api/graphQL/queryModels";

export interface ComponentProps {
    beaconStory: BeaconStoryInput;
    beaconItemSummaries: BeaconItem[];
    onSave: (story: BeaconStoryInput) => unknown;
    isSaving: boolean;
    clientId: number | null;
    isNew: boolean;
}

const StoryEditor = (props: ComponentProps) => {
    const { beaconStory, onSave, isSaving, clientId, isNew } = props;

    const formik = useFormik<BeaconStoryInput>({
        initialValues: {
            ...beaconStory
        },
        onSubmit: (story) => {
            onSave(story);
        }
    });

    if (!props.beaconItemSummaries) {
        return null;
    }

    return (
        beaconStory && (
            <Form onSubmit={formik.handleSubmit} fullWidth>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4"> {isNew ? "Create a new story" : "Edit story"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StoryEditorAboutSection
                            title={formik.values.title}
                            previewDescription={formik.values.previewDescription}
                            description={formik.values.description}
                            handleChange={formik.handleChange}
                            setFieldValue={formik.setFieldValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StoryEditorOutcomesSection
                            value={formik.values.outcome}
                            onChange={(value) => {
                                formik.setFieldValue(
                                    nameof<BeaconStoryInput>((s) => s.outcome),
                                    value
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StoryEditorImagesSection
                            value={formik.values.images}
                            onChange={(value) => {
                                formik.setFieldValue(
                                    nameof<BeaconStoryInput>((s) => s.images),
                                    value
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StoryEditorProjectSection
                            story={formik.values}
                            clientId={clientId}
                            setFieldValue={formik.setFieldValue}
                            handleChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StoryEditorLinkedItemsSection
                            value={formik.values.selectedItems}
                            onChange={(value) => {
                                formik.setFieldValue(
                                    nameof<BeaconStoryInput>((s) => s.selectedItems),
                                    value
                                );
                            }}
                            itemSummaries={props.beaconItemSummaries}
                        />
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item>
                            <Button type="submit" variant="contained" disabled={isSaving} style={{ width: "8rem" }}>
                                {formik.values.isPublished ? "Publish story" : "Save draft"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button component={RouterLink} to={`/?view=stories`}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <StoryEditorPublishSection
                                isPublished={formik.values.isPublished}
                                onPublishedChange={(isPublished) =>
                                    formik.setFieldValue(
                                        nameof<BeaconStoryInput>((s) => s.isPublished),
                                        isPublished
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )
    );
};

export default StoryEditor;
