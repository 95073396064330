import * as React from "react";
import { Select, MenuItem, Chip, FormControl, InputLabel } from "@material-ui/core";
import { BeaconItemTag } from "../../../api/graphQL/queryModels";

interface TagsFieldProps {
    label?: string;
    availableTags: BeaconItemTag[];
    value: BeaconItemTag[];
    onChange: (value: BeaconItemTag[]) => unknown;
}

const TagsField = (props: TagsFieldProps) => {
    const { label, availableTags, value } = props;

    const onTagsChanged = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const selectedIds = event.target.value as string[];

        const availableTags = props.availableTags;
        const selectedTags = availableTags.filter((t) => selectedIds.indexOf(t.id) >= 0);
        props.onChange(selectedTags);
    };
    return (
        <FormControl variant="outlined" fullWidth>
            {label ? <InputLabel htmlFor="tags-field">{label}</InputLabel> : null}
            <Select
                id="tags-field"
                variant="outlined"
                multiple
                value={value.map((t) => t.id)}
                onChange={onTagsChanged}
                fullWidth
                renderValue={(selected) => {
                    return (
                        <div className="tags-field-chip-list">
                            {(selected as string[]).map((tagId) => {
                                const tag = availableTags.find((t) => t.id === tagId);
                                return tag ? <Chip key={tagId} label={tag.name} className="tags-field-chip" /> : null;
                            })}
                        </div>
                    );
                }}
            >
                {availableTags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                        {tag.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TagsField;
