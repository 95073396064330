import * as React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

type sizeVariant = "sm" | "md" | "lg" | "xl";
type shapeVariant = "circular" | "rounded" | "square";
interface ComponentProps {
    text: string;
    image?: string;
    color?: string;
    size?: sizeVariant;
    shape?: shapeVariant;
    isActive?: boolean;
    hasBorder?: boolean;
}

const useStyles = makeStyles(() => ({
    sm: { width: "28px", height: "28px", fontSize: "0.9rem" },
    md: { width: "40px", height: "40px", fontSize: "1.1rem" },
    lg: { width: "74px", height: "74px" },
    xl: { width: "130px", height: "130px" }
}));

const ColoredAvatar = (props: ComponentProps) => {
    const classes = useStyles();
    return (
        <div className={`avatar-icon-container ${!props.isActive ? "avatar-icon-inactive" : ""}`}>
            <Avatar
                className={classes[props.size || "md"]}
                src={props.image ? props.image : undefined}
                variant={props.shape || "circular"}
                style={{ backgroundColor: props.color, border: props.hasBorder ? "2px solid white" : "none" }}
            >
                {props.text}
            </Avatar>
        </div>
    );
};

export default ColoredAvatar;
