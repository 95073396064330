import * as React from "react";

import { FormControl, FormLabel } from "@material-ui/core";
import SimpleMDE from "react-simplemde-editor";
import { Options as EasyMDEOptions } from "easymde";

export interface MarkdownEditorProps {
    labelText?: string;
    value: string;
    allowImages?: boolean;
    onChange: (value: string) => unknown;
}

const MarkdownEditor = (props: MarkdownEditorProps) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    const simpleMDEOptions = React.useMemo(() => {
        return {
            spellChecker: false,
            hideIcons: (props.allowImages ? [] : ["image"]).concat(["side-by-side", "fullscreen", "guide"]).filter((i) => i != null),
            status: false,
            minHeight: "100px"
        } as EasyMDEOptions;
    }, [props.allowImages]);

    return (
        <FormControl fullWidth className={`markdown-editor ${isFocused ? "focused" : ""}`}>
            <FormLabel className={props.labelText ? "" : "empty"}>{props.labelText ? props.labelText : null}</FormLabel>
            <SimpleMDE value={props.value} onChange={props.onChange} events={{ focus: onFocus, blur: onBlur }} options={simpleMDEOptions} />
        </FormControl>
    );
};

export default MarkdownEditor;
