import { useQuery } from "@apollo/client";
import React from "react";
import { GET_BEACON_STORIES_DATA_QUALITY } from "../../../api/graphQL/queries";
import { BeaconStoryDataQualityReportConnection, BeaconStoryDataQualityReport } from "../../../api/graphQL/queryModels";
import StoriesDataQualityTable from "./StoriesDataQualityTable";

const pageSize = 20;
const StoriesDataQualityView: React.FunctionComponent<object> = () => {
    const { data, loading, fetchMore } = useQuery<{ dataQuality: BeaconStoryDataQualityReportConnection }>(
        GET_BEACON_STORIES_DATA_QUALITY,
        {
            variables: { first: 20 }
        }
    );
    const storyReports = (data?.dataQuality.beaconStories.edges.map((e) => e.node) as BeaconStoryDataQualityReport[]) || [];
    const handleLoadMore = () => {
        fetchMore({
            variables: { first: pageSize, after: data?.dataQuality.beaconStories.pageInfo.endCursor },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.dataQuality.beaconStories.edges;
                return newEdges?.length
                    ? {
                          dataQuality: {
                              beaconStories: {
                                  __typename: previousResult.dataQuality.beaconStories.__typename,
                                  edges: [...previousResult.dataQuality.beaconStories.edges, ...newEdges],
                                  pageInfo: fetchMoreResult.dataQuality.beaconStories.pageInfo
                              }
                          }
                      }
                    : previousResult;
            }
        });
    };
    return (
        <StoriesDataQualityTable
            reports={storyReports}
            loading={loading}
            hasMore={!!data?.dataQuality.beaconStories.pageInfo.hasNextPage}
            fetchMore={handleLoadMore}
        />
    );
};

export default StoriesDataQualityView;
