export interface Person {
    id: string;
    givenName: string;
    surname: string;
    fullName: string;
    jobTitle: string;
    bio: string | null;
    emailAddress: string;
    phoneNumber: string;
    officeLocation: string;
    photos: PersonPhoto[];
    manager: Person | null;
    directReports: Person[];
    workingHours: PersonWorkingHours | null;
    isEmployee: boolean;
    isContractor: boolean;
    isEnabled: boolean;
}

export interface PersonDetail extends Person {
    skills?: PersonSkill[];
    education?: PersonEducation[];
    projectParticipations?: GraphQlConnection<ProjectParticipant>;
}

export interface PersonSkill {
    id: number;
    beaconItem?: BeaconItem;
    person?: Person;
    isLoved: boolean;
}

export interface BeaconItem {
    id: string;
    name: string;
    category?: BeaconItemCategory;
    tags?: BeaconItemTag[];
    endorsement?: BeaconItemEndorsement;
    people: string;
    preparation: string;
    duration: string;
    previewDescription: string;
    description?: string;
    difficulty: BeaconItemDifficulty;
    author?: Person;
    dateCreated: Date | string | null;
    stories?: BeaconStory[];
    isDeleted: boolean;
    isArchived: boolean;
    skilledPeople?: PersonSkill[];
    resumeGroup: BeaconItemResumeGroup | null;
}

export interface BeaconItemResumeGroup {
    id: string;
    name: string;
}

export enum BeaconItemEndorsement {
    None = "NONE",
    Adopt = "ADOPT",
    Consider = "CONSIDER",
    Avoid = "AVOID"
}

export enum BeaconItemCategory {
    None = "NONE",
    Technique = "TECHNIQUE",
    Tool = "TOOL",
    Technology = "TECHNOLOGY",
    Methodology = "METHODOLOGY"
}

export enum BeaconItemDifficulty {
    None = "NONE",
    Easy = "EASY",
    Medium = "MEDIUM",
    Hard = "HARD"
}

export interface BeaconItemTag {
    id: string;
    name: string;
    isDeleted: boolean;
}

export interface PersonEducation {
    id: number;
    personId: string;
    type: PersonEducationType;
    institution: string;
    name: string;
    description: string | null;
    linkUri: string | null;
    dateAchieved: Date | string;
}

export enum PersonEducationType {
    Unknown = "UNKNOWN",
    Degree = "DEGREE",
    Diploma = "DIPLOMA",
    Certification = "CERTIFICATION",
    Course = "COURSE"
}

export interface ProjectParticipant {
    contributedTimeInMinutes: number;
    role: string | null;
    duties: string | null;
    project: Project;
    person: Person;
    skillsUsed: ProjectParticipantSkill[];
    lastTimeEntryDate?: Date;
}

export interface ProjectParticipantSkill {
    beaconItem: BeaconItem;
}

export interface Project {
    id: number;
    name: string;
    description: string;
    startDate: Date | string | null;
    dueDate: Date | string | null;
    problem: string | null;
    solution: string | null;
    outcome: string | null;
    status: ProjectStatus;
    isArchived: boolean;
    participants: ProjectParticipant[];
    clients?: Client[];
    studios: Studio[];
}

export enum ProjectStatus {
    Unknown = "UNKNOWN",
    Contingent = "CONTINGENT",
    InSetup = "IN_SETUP",
    Active = "ACTIVE",
    ActiveOnWatchList = "ACTIVE_ON_WATCH_LIST",
    OnHold = "ON_HOLD",
    Completed = "COMPLETED",
    Cancelled = "CANCELLED"
}

export const getProjectStatusFriendlyName = (status: ProjectStatus) => {
    switch (status) {
        case ProjectStatus.Contingent:
            return "Contingent";
        case ProjectStatus.InSetup:
            return "In setup";
        case ProjectStatus.Active:
            return "Active";
        case ProjectStatus.ActiveOnWatchList:
            return "Active - watch list";
        case ProjectStatus.OnHold:
            return "On hold";
        case ProjectStatus.Completed:
            return "Completed";
        case ProjectStatus.Cancelled:
            return "Cancelled";
        default:
            return "Unknown";
    }
};

export interface Client {
    id: number;
    name: string;
    logoUri?: string;
    projects?: Project[];
}

export interface Studio {
    id: number;
    name: string;
}

export interface PersonPhoto {
    uri: string;
    size: "xs" | "sm" | "md" | "lg" | "xl";
}

export interface PersonWorkingHours {
    daysOfWeek: string[];
    startTime: string;
    endTime: string;
    timeZone: string;
}

export interface GraphQlConnection<T> {
    __typename?: string;
    edges: {
        cursor: string;
        node: T;
    }[];
    pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
        endCursor: string;
    };
    totalCount?: number;
}

export interface PersonProfileInput {
    id: string;
    bio: string | null;
}

export interface BeaconStory {
    id: string;
    title: string;
    author: Person;
    headerImageUrl: string | null;
    dateCreated: Date | string;
    datePublished: Date | string | null;
    description: string;
    images: BeaconStoryImageMetadata[];
    isPublished: boolean;
    isArchived: boolean;
    outcome: BeaconStoryOutcome;
    previewDescription: string;
    project?: Project;
    projectStatus: BeaconStoryProjectStatus;
    selectedItems: BeaconStorySelectedItem[];
    commentList?: BeaconStoryCommentList;
}

export interface BeaconStoryCommentList {
    id: string;
    comments: BeaconStoryComment[];
}

export interface BeaconStoryComment {
    id: string;
    author: Person;
    text: string;
    dateCreated: Date | string | null;
}

export interface BeaconImageUploadToken {
    uniqueFilename: string;
    fileUrl: string;
    sasToken: string;
}

export interface BeaconStoryImageMetadata {
    description: string;
    showInGallery: boolean;
    showInHeader: boolean;
    filename: string;
    url: string;
    optimizedFilename: string;
    optimizedUrl: string;
    thumbnailFilename: string;
    thumbnailUrl: string;
    title: string;
}

export interface BeaconStoryOutcome {
    accomplishmentSentiment: number;
    clientSentiment: number;
    difficultySentiment: number;
    failureNotes: string;
    improvementNotes: string;
    stressLevelSentiment: number;
    successNotes: string;
    teamCohesivenessSentiment: number;
}

export enum BeaconStoryProjectStatus {
    NotStarted = "NOT_STARTED",
    InProgress = "IN_PROGRESS",
    Completed = "COMPLETED"
}

export enum BeaconStorySelectedItemOutcomeSentiment {
    Neutral = "NEUTRAL",
    Positive = "POSITIVE",
    Negative = "NEGATIVE",
    Mixed = "MIXED"
}

export interface BeaconStorySelectedItemOutcome {
    justification: string;
    sentiment: BeaconStorySelectedItemOutcomeSentiment;
}

export interface BeaconStorySelectedItem {
    consideredAlternativeItems: BeaconItem[];
    explanation: string;
    outcome: BeaconStorySelectedItemOutcome;
    selectedItem: BeaconItem;
    title: string;
}

export interface BeaconContentSearchResultHighlight {
    matchingFieldName: string;
    matchingTextFragments: string[];
}

export interface BeaconContentSearchResult {
    id: string;
    type: string;
    title: string;
    previewDescription: string;
    isArchived: boolean;
    description: string;
    itemTagNames: string[];
    storyLinkedItemNames: string[];
    highlights: BeaconContentSearchResultHighlight[];
}

export interface BeaconContentSearchResponse {
    searchId: string;
    results: BeaconContentSearchResult[];
    totalResultsCount: number;
}

export interface BeaconItemDataQualityReport {
    beaconItem: Pick<BeaconItem, "name" | "id" | "author">;
    entityId: string;
    overallScore: number;
    entityType: string;
    fields: FieldDataQuality[];
}

export interface BeaconStoryDataQualityReport {
    beaconStory: Pick<BeaconStory, "title" | "id">;
    entityId: string;
    overallScore: number;
    entityType: string;
    fields: FieldDataQuality[];
}

export interface DataQualityReportEdge {
    __typename?: string;
    cursor: string;
    node: BeaconItemDataQualityReport | BeaconStoryDataQualityReport;
}

export interface DataReport {
    edges: DataQualityReportEdge[];
    pageInfo: PageInfo;
    __typename: string;
}

export interface PageInfo {
    hasNextPage: boolean;
    endCursor: number;
}

export interface BeaconItemDataQualityReportConnection {
    beaconItems: DataReport;
}

export interface BeaconStoryDataQualityReportConnection {
    beaconStories: DataReport;
}

export type DataQualityRuleSummary = Pick<DataQualityRule, "description">;

export type FieldDataQualitySummary = Pick<FieldDataQuality, "fieldName" | "score"> & {
    violations: DataQualityRuleSummary[];
};

export type FieldDataQuality = {
    __typename?: string;
    fieldName: string;
    entityId?: string;
    score: number;
    weight: number;
    violations: DataQualityRule[];
    children: FieldDataQuality[];
};

export interface DataQualityRule {
    __typename?: string;
    description: string;
}
