import * as React from "react";

import {
    Card,
    ButtonBase,
    CardMedia,
    Typography,
    CardActions,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    FormControlLabel,
    Switch,
    TextField,
    Grid
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { BeaconStoryImageMetadataInput } from "../../../api/graphQL/mutationModels";

export interface StoryImageListItemState {
    open: boolean;
}

export interface StoryImageListItemProps {
    image: BeaconStoryImageMetadataInput;
    onImagePropChange: (propNames: (keyof BeaconStoryImageMetadataInput)[], value: Partial<BeaconStoryImageMetadataInput>) => unknown;
    onDeleteRequested: () => unknown;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...(props as object)} />;
});

const StoryEditorImageListItem = (props: StoryImageListItemProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onImagePropChange = (propName: keyof BeaconStoryImageMetadataInput) => (event: any) => {
        props.onImagePropChange([propName], {
            [propName]: propName === "title" || propName === "description" ? event.target.value : event.target.checked
        });
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const { image } = props;
    const imageUrl = image.thumbnailUrl || image.optimizedUrl || image.url;

    return (
        <>
            <Card className={`attachment-preview`}>
                {imageUrl ? (
                    <div className="attachment-preview-img-wrapper">
                        <ButtonBase className="attachment-preview-img-wrapper" focusRipple onClick={handleOpen}>
                            <CardMedia className="attachment-preview-img" image={imageUrl} title={image.title} />
                        </ButtonBase>
                    </div>
                ) : (
                    <div className="attachment-preview-img-wrapper">
                        <div className="uploading-bg">
                            <Typography className="uploading-msg" variant="h5" component="div">
                                Uploading...
                            </Typography>
                        </div>
                    </div>
                )}
                <CardActions className="attachment-preview-actions">
                    <Button
                        className="preview-attachment-button"
                        size="small"
                        color="primary"
                        onClick={handleOpen}
                        disabled={!image.filename}
                    >
                        Preview
                    </Button>
                    <Button
                        className="delete-attachment-button"
                        color="primary"
                        style={{ marginLeft: "auto" }}
                        onClick={props.onDeleteRequested}
                        disabled={!image.filename}
                    >
                        Delete
                    </Button>
                </CardActions>
                {/* {image.percentUploaded && (
                    <LinearProgress
                        className={`attachment-progress ${uploadInProgress ? "in-progress" : "complete"}`}
                        variant="determinate"
                        value={image.percentUploaded || undefined}
                    />
                )} */}
                <CardActions>
                    <FormControlLabel
                        control={<Switch checked={image.showInHeader} onChange={onImagePropChange("showInHeader")} color="primary" />}
                        label="Header"
                    />

                    <FormControlLabel
                        control={<Switch checked={image.showInGallery} onChange={onImagePropChange("showInGallery")} color="primary" />}
                        label="Gallery"
                    />
                </CardActions>
                <CardActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField label="Title" value={image.title || ""} fullWidth onChange={onImagePropChange("title")} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                value={image.description || ""}
                                fullWidth
                                multiline
                                onChange={onImagePropChange("description")}
                            />
                        </Grid>
                    </Grid>
                </CardActions>

                <span className="image-url">{image.optimizedUrl}</span>
            </Card>
            <Dialog
                className="working-attachment-preview-dialog"
                fullScreen
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar color="primary">
                    <Toolbar className="attachment-preview-dialog-toolbar">
                        <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                            <i className="material-icons">close</i>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className="attachment-preview-dialog-title">
                            {image.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className="attachment-preview-dialog-content">
                    {<img src={image.optimizedUrl || image.url} alt={image.description || image.filename || ""} />}
                </div>
            </Dialog>
        </>
    );
};

export default StoryEditorImageListItem;
