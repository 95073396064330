import * as React from "react";
import { Slider, Typography, FormHelperText } from "@material-ui/core";
import { FormSection, FormField, FormControl } from "../forms";
import { MarkdownEditor } from "../misc";
import StoryOutcomeSentimentFormatter from "./StoryOutcomeSentimentFormatter";
import { BeaconStoryOutcomeInput } from "../../../api/graphQL/mutationModels";

interface StoryEditorOutcomesSectionProps {
    value: BeaconStoryOutcomeInput;
    onChange: (value: BeaconStoryOutcomeInput) => unknown;
}

const StoryEditorOutcomesSection = (props: StoryEditorOutcomesSectionProps) => {
    const onOutcomeChange = (propName: keyof BeaconStoryOutcomeInput) => (event: unknown, newValue?: unknown) => {
        const value = propName === "successNotes" || propName === "failureNotes" || propName === "improvementNotes" ? event : newValue;
        const newOutcome = {
            ...props.value,
            [propName]: value
        };
        props.onChange(newOutcome);
    };

    const { value } = props;
    return (
        <FormSection title="Outcomes" fullWidth>
            <FormField>
                <FormControl>
                    <Typography>Difficulty</Typography>
                    <Typography variant="caption" gutterBottom>
                        How hard was this project?
                    </Typography>
                    <Slider
                        value={value.difficultySentiment}
                        min={0}
                        max={10}
                        step={1}
                        valueLabelDisplay="auto"
                        onChange={onOutcomeChange("difficultySentiment")}
                        marks
                    />
                    <FormHelperText className="outcomes-helper-text">
                        {StoryOutcomeSentimentFormatter.formatSentimentCaption("difficultySentiment", value.difficultySentiment)}
                    </FormHelperText>
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <Typography>Client sentiment</Typography>
                    <Typography variant="caption" gutterBottom>
                        Was the client happy?
                    </Typography>
                    <Slider
                        value={value.clientSentiment}
                        min={0}
                        max={10}
                        step={1}
                        valueLabelDisplay="auto"
                        onChange={onOutcomeChange("clientSentiment")}
                        marks
                    />
                    <FormHelperText className="outcomes-helper-text">
                        {StoryOutcomeSentimentFormatter.formatSentimentCaption("clientSentiment", value.clientSentiment)}
                    </FormHelperText>
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <Typography>Team sentiment</Typography>
                    <Typography variant="caption" gutterBottom>
                        Was the team happy with the result?
                    </Typography>
                    <Slider
                        value={value.accomplishmentSentiment}
                        min={0}
                        max={10}
                        step={1}
                        valueLabelDisplay="auto"
                        onChange={onOutcomeChange("accomplishmentSentiment")}
                        marks
                    />
                    <FormHelperText className="outcomes-helper-text">
                        {StoryOutcomeSentimentFormatter.formatSentimentCaption("accomplishmentSentiment", value.accomplishmentSentiment)}
                    </FormHelperText>
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <Typography>Team cohesiveness</Typography>
                    <Typography variant="caption" gutterBottom>
                        Did the team work well together?
                    </Typography>
                    <Slider
                        value={value.teamCohesivenessSentiment}
                        min={0}
                        max={10}
                        step={1}
                        valueLabelDisplay="auto"
                        onChange={onOutcomeChange("teamCohesivenessSentiment")}
                        marks
                    />
                    <FormHelperText className="outcomes-helper-text">
                        {StoryOutcomeSentimentFormatter.formatSentimentCaption(
                            "teamCohesivenessSentiment",
                            value.teamCohesivenessSentiment
                        )}
                    </FormHelperText>
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <Typography>Stress level</Typography>
                    <Typography variant="caption" gutterBottom>
                        Was the project team under pressure?
                    </Typography>
                    <Slider
                        value={value.stressLevelSentiment}
                        min={0}
                        max={10}
                        step={1}
                        valueLabelDisplay="auto"
                        onChange={onOutcomeChange("stressLevelSentiment")}
                        marks
                    />
                    <FormHelperText className="outcomes-helper-text">
                        {StoryOutcomeSentimentFormatter.formatSentimentCaption("stressLevelSentiment", value.stressLevelSentiment)}
                    </FormHelperText>
                </FormControl>
            </FormField>
            <FormField fullWidth>
                <FormControl>
                    <MarkdownEditor labelText="What went well?" value={value.successNotes} onChange={onOutcomeChange("successNotes")} />
                </FormControl>
            </FormField>
            <FormField fullWidth>
                <FormControl>
                    <MarkdownEditor
                        labelText="What did not go well?"
                        value={value.failureNotes}
                        onChange={onOutcomeChange("failureNotes")}
                    />
                </FormControl>
            </FormField>
            <FormField fullWidth>
                <FormControl>
                    <MarkdownEditor
                        labelText="What could be improved? What would you do differently?"
                        value={value.improvementNotes}
                        onChange={onOutcomeChange("improvementNotes")}
                    />
                </FormControl>
            </FormField>
        </FormSection>
    );
};

export default StoryEditorOutcomesSection;
