const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function formatDollar(num: number) {
    const p = num.toFixed(2).split(".");
    const chars = p[0].split("").reverse();
    let newstr = "";
    let count = 0;
    for (let i = 0; i < chars.length; i++) {
        count++;
        if (count % 3 === 1 && count !== 1) {
            newstr = chars[i] + "," + newstr;
        } else {
            newstr = chars[i] + newstr;
        }
    }
    return newstr + "." + p[1];
}

const Formatter = {
    isValidDate: (date: Date | string | null): boolean => {
        if (date == null) {
            return false;
        }
        const dateObj = new Date(date);
        // eslint-disable-next-line no-self-compare
        return dateObj != null && dateObj.getTime() === dateObj.getTime();
    },
    formatCurrency: (amount: number, dollarSignRequired = true, truncateDecimal = false): string => {
        if (amount === null) {
            return "";
        }

        let formattedDollarAmount = formatDollar(Math.abs(amount));
        if (formattedDollarAmount.endsWith(".00") && truncateDecimal) {
            formattedDollarAmount = formattedDollarAmount.substr(0, formattedDollarAmount.length - 3);
        }

        return dollarSignRequired
            ? amount >= 0
                ? "$" + formattedDollarAmount
                : "($" + formattedDollarAmount + ")"
            : formattedDollarAmount;
    },
    formatNumber: (amount: number, truncateDecimal?: boolean): string => {
        if (amount === null) {
            return "";
        }

        let formattedDollarAmount = formatDollar(Math.abs(amount));
        if (formattedDollarAmount.endsWith(".00") && truncateDecimal) {
            formattedDollarAmount = formattedDollarAmount.substr(0, formattedDollarAmount.length - 3);
        }

        return `${amount < 0 ? "-" : ""}${formattedDollarAmount}`;
    },
    formatInt: (value: number) => {
        return Formatter.formatNumber(value, true);
    },
    formatLocalDate: (date: Date | string | null, includeYear = true, includeTime = false): string => {
        if (date) {
            const formatAmPm = (dateToAmPm: Date): string => {
                let hours = dateToAmPm.getHours();
                const minutes = dateToAmPm.getMinutes();
                const ampm = hours >= 12 ? "p.m." : "a.m.";
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                const minutesStr = minutes < 10 ? "0" + minutes : minutes;
                const strTime = hours + ":" + minutesStr + " " + ampm;
                return strTime;
            };

            const dateToConvert = Formatter.convertToDateObject(date);

            return dateToConvert
                ? monthShortNames[dateToConvert.getMonth()] +
                      " " +
                      dateToConvert.getDate() +
                      ", " +
                      (includeYear ? " " + dateToConvert.getFullYear() : "") +
                      (includeTime ? " " + formatAmPm(dateToConvert) : "")
                : "";
        }

        return "";
    },
    convertToDateObject: (date: Date | string | null): Date | null => {
        if (date === null) {
            return null;
        }
        let convertedDate = null;

        if (typeof date === "string" && !Formatter.dateStringHasTimezone(date)) {
            convertedDate = Formatter.convertUtcDateToLocalWithSameTime(date + "Z");

            // browser inconsistencies
            if (isNaN(Number(convertedDate))) {
                convertedDate = Formatter.convertUtcDateToLocalWithSameTime(date);
            }
        } else {
            convertedDate = new Date(date);
        }
        return convertedDate;
    },
    convertUtcDateToLocalWithSameTime: (utcDate: Date | string | null): Date | null => {
        if (utcDate === null) {
            return null;
        }

        const utcDateObj = utcDate != null ? new Date(utcDate) : null;
        let localDate: Date | null = null;

        if (utcDateObj != null) {
            const userTimezoneOffset = utcDateObj.getTimezoneOffset() * 60000;
            localDate = new Date(utcDateObj.getTime() + userTimezoneOffset);
        }

        return localDate;
    },
    convertLocalDateToUtcDateWithSameTime: (localDate: Date | string | null): Date | null => {
        let utcDate: Date | null = null;
        const localDateObj = localDate != null ? new Date(localDate) : null;
        if (localDateObj != null) {
            const userTimezoneOffset = localDateObj.getTimezoneOffset() * 60000;
            utcDate = new Date(localDateObj.getTime() - userTimezoneOffset);
        }
        return utcDate;
    },
    formatSecondsToMinutes: (seconds: number): string => {
        const milliseconds = seconds * 1000;
        const minutes = Math.floor(milliseconds / 60000);
        const formattedSeconds = (milliseconds % 60000) / 1000;
        return formattedSeconds === 60
            ? minutes + 1 + ":00"
            : minutes + ":" + (formattedSeconds < 10 ? "0" : "") + formattedSeconds.toFixed(0);
    },
    dateStringHasTimezone: (date: string): boolean => {
        return (
            date.indexOf("Z") > 0 ||
            date.lastIndexOf("-") > 7 || // we need to ignore the date part as we are only looking for dash near the end of the string
            date.indexOf("+") > 0
        );
    },
    formatPhoneNumber: (phoneNumber: string): string => {
        // removing symbols from phone number
        // eslint-disable-next-line no-useless-escape
        const cleanPhoneNumber = phoneNumber.replace(/[\(\)\s\-]/g, "");

        let formattedPhoneNumber = "";
        let internationalCode = "";
        let areaCode = "";
        let prefix = "";
        let lineNumber = "";

        if (cleanPhoneNumber) {
            const phoneNumberLength = cleanPhoneNumber.length;
            lineNumber = phoneNumberLength > 3 ? cleanPhoneNumber.substring(phoneNumberLength - 4) : cleanPhoneNumber;

            if (phoneNumberLength > 4) {
                prefix = cleanPhoneNumber.slice(0, phoneNumberLength - 4);
                prefix = prefix.slice(prefix.length - 3);
            }
            if (phoneNumberLength > 7) {
                areaCode = cleanPhoneNumber.slice(0, phoneNumberLength - 7);
                areaCode = areaCode.slice(areaCode.length - 3);
            }
            if (phoneNumberLength > 10) {
                internationalCode = cleanPhoneNumber.slice(0, phoneNumberLength - 10);
            }
        }
        if (internationalCode) {
            formattedPhoneNumber = internationalCode + "-";
        }
        if (areaCode) {
            formattedPhoneNumber = formattedPhoneNumber + areaCode + "-";
        }
        if (prefix) {
            formattedPhoneNumber = formattedPhoneNumber + prefix + "-";
        }
        if (lineNumber) {
            formattedPhoneNumber = formattedPhoneNumber + lineNumber;
        }

        return formattedPhoneNumber;
    }
};
export default Formatter;
