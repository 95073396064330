import * as React from "react";
import { Icon, makeStyles } from "@material-ui/core";
import { BeaconStorySelectedItemOutcomeSentiment } from "../../../api/graphQL/queryModels";
import { Alert } from "@material-ui/lab";

interface StoryLinkedItemOutcomeSentimentBadgeProps {
    sentiment: BeaconStorySelectedItemOutcomeSentiment;
}

interface AlertInfo {
    alertClass: string;
    iconName: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.offCommon.black,
        "& .MuiAlert-message": {
            paddingTop: theme.spacing(1.25),
            fontSize: "1rem"
        }
    },
    neutral: {
        backgroundColor: theme.palette.offCommon.white
    },
    positive: {
        backgroundColor: theme.palette.success.main
    },
    negative: {
        backgroundColor: theme.palette.error.main
    },
    mixed: {
        backgroundColor: theme.palette.warning.main
    }
}));

const StoryLinkedItemOutcomeSentimentBadge = (props: StoryLinkedItemOutcomeSentimentBadgeProps) => {
    const { sentiment } = props;
    const classes = useStyles();

    const alertInfo: AlertInfo = { alertClass: "", iconName: "" };

    switch (sentiment) {
        case BeaconStorySelectedItemOutcomeSentiment.Neutral:
            alertInfo.alertClass = classes.neutral;
            alertInfo.iconName = "sentiment_satisfied";
            break;
        case BeaconStorySelectedItemOutcomeSentiment.Positive:
            alertInfo.alertClass = classes.positive;
            alertInfo.iconName = "sentiment_very_satisfied";
            break;
        case BeaconStorySelectedItemOutcomeSentiment.Negative:
            alertInfo.alertClass = classes.negative;
            alertInfo.iconName = "sentiment_very_dissatisfied";
            break;
        case BeaconStorySelectedItemOutcomeSentiment.Mixed:
            alertInfo.alertClass = classes.mixed;
            alertInfo.iconName = "sentiment_dissatisfied";
            break;
    }

    return (
        <Alert variant="filled" className={`${classes.root} ${alertInfo.alertClass}`} icon={<Icon>{alertInfo.iconName}</Icon>}>
            {sentiment}
        </Alert>
    );
};

export default StoryLinkedItemOutcomeSentimentBadge;
