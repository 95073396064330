import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Grid, Button, Link, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InventoryIcon from "@mui/icons-material/Inventory";

import { BeaconStory } from "../../../api/graphQL/queryModels";
import { useUserContext } from "../auth/UserContextProvider";
import StoryArchive from "./StoryArchive";
import ArchivedDisplay from "../items/ArchivedDisplay";

interface ComponentProps {
    story: BeaconStory;
}

const useStyles = makeStyles((theme) => ({
    storyHeader: {
        color: "#fff"
    },
    background: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        background: "linear-gradient(90deg, #000, #00675B)"
    },
    overlay: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2)
        }
    },
    contentProject: {
        opacity: 0.75
    },
    editButton: {
        color: theme.palette.common.white
    },
    button: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        marginBottom: theme.spacing(2.3),
        marginLeft: theme.spacing(1.5)
    }
}));

const StoryHeader = (props: ComponentProps) => {
    const classes = useStyles();
    const { story } = props;

    const { user, isContentAdmin } = useUserContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const canEdit =
        !!user?.id &&
        (user.id === story.author?.id ||
            isContentAdmin ||
            (story.project?.participants || []).some((participant) => !!participant && participant.person.id === user.id));

    return (
        <div className={classes.storyHeader}>
            <div className={classes.background}>
                <div className={classes.overlay}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h3" color="inherit">
                                {story.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {story.isArchived && <ArchivedDisplay />}
                            {!story.isArchived && canEdit ? (
                                <Button
                                    component={RouterLink}
                                    to={`/stories/${story.id}/edit`}
                                    startIcon={<EditIcon className={classes.editButton} />}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    {"Edit"}
                                </Button>
                            ) : null}
                            {canEdit ? (
                                <Button
                                    onClick={() => setIsOpen(true)}
                                    startIcon={<InventoryIcon className={classes.editButton} />}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    {!story.isArchived ? "Archive" : "Unarchive"}
                                </Button>
                            ) : null}
                            {isOpen && <StoryArchive isOpen={isOpen} setIsOpen={setIsOpen} />}
                        </Grid>
                        <Grid item xs={12}>
                            {story.project ? (
                                <>
                                    <Typography variant="h6" color="inherit" className={classes.contentProject}>
                                        {story.project.clients
                                            ? story.project.clients.map((client) => {
                                                  return (
                                                      <Link
                                                          color="inherit"
                                                          href={`https://projects.devfacto.com/clients/${client.id}`}
                                                          key="Link 1"
                                                      >
                                                          {client.name}
                                                      </Link>
                                                  );
                                              })
                                            : null}
                                        {story.project && story.project.clients ? " • " : ""}
                                        {story.project ? (
                                            <Link color="inherit" href={`https://projects.devfacto.com/projects/${story.project.id}`}>
                                                {story.project?.name}
                                            </Link>
                                        ) : null}
                                    </Typography>
                                    <Typography variant="h6" color="inherit" className={classes.contentProject}>
                                        {story.project.studios.map((s) => s.name).join(" • ") || ""}
                                    </Typography>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default StoryHeader;
