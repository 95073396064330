import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as React from "react";
import { BeaconItem } from "../../../api/graphQL/queryModels";

interface ItemHeaderProps {
    item: BeaconItem;
    children: React.ReactNode;
}

const ItemHeader = (props: ItemHeaderProps) => {
    const { item } = props;
    return (
        <Grid container spacing={2} direction="column" alignItems="stretch">
            <Grid item>
                {item.endorsement === "AVOID" ? (
                    <Alert color="error" variant="filled" severity="error">{`${item.endorsement}`}</Alert>
                ) : null}
            </Grid>
            <Grid item>
                <div>{props.children}</div>
            </Grid>
        </Grid>
    );
};

export default ItemHeader;
