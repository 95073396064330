import React from "react";
import { InputBase } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === "dark";
    return {
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: isDarkMode ? theme.palette.offCommon.black : theme.palette.offCommon.white,
            border: `1px solid ${isDarkMode ? theme.palette.common.white : alpha(theme.palette.offCommon.black, 0.23)}`,
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.15)",
                cursor: "pointer"
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto"
            }
        },
        searchIcon: {
            width: theme.spacing(7),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        searchText: {
            color: isDarkMode ? theme.palette.offCommon.white : alpha(theme.palette.common.black, 1)
        },
        inputRoot: {},
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: 200
            },
            "&:hover": {
                cursor: "pointer"
            }
        },
        iconButton: {
            fontSize: 32,
            marginRight: theme.spacing(1),
            color: theme.palette.common.black
        }
    };
});

interface SearchBaitProps {
    searchText: string;
    onClick: () => unknown;
}

const SearchBait = (props: SearchBaitProps) => {
    const classes = useStyles();

    const { searchText } = props;
    return (
        <>
            <div className={`${classes.search}`}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder={searchText || "Search"}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    inputProps={{
                        className: classes.searchText,
                        "aria-label": "Search"
                    }}
                    onClick={props.onClick}
                    onFocus={(e) => e.currentTarget.blur()}
                    value=""
                />
            </div>
        </>
    );
};

export default React.memo(SearchBait);
