import * as React from "react";
import { useAppContextApi } from "./api/AppContextApiProvider";
import { AuthContextProvider } from "./pages/components/auth/AuthProvider";
import { LoadingView } from "@devfacto/react-components";
import { ApplicationContext } from "./api/AppContextApi";

interface AppContextValue {
    context: ApplicationContext | null;
}
export const AppContext = React.createContext<AppContextValue>({ context: null });
export const useAppContext = () => React.useContext(AppContext);

const AppContextProvider = (props: { children: React.ReactNode }) => {
    const [appContext, setAppContext] = React.useState<ApplicationContext>();
    const api = useAppContextApi();

    React.useEffect(() => {
        async function loadApplicationContext() {
            const response = await api.appContext.getApplicationContext();
            setAppContext(response.data);
            // ai.initialize(response.data.settings.appInsightsKey);
        }
        loadApplicationContext();
    }, [api.appContext]);

    return appContext ? (
        <AuthContextProvider settings={appContext.settings.authSettings}>
            <AppContext.Provider value={{ context: appContext }}>{props.children}</AppContext.Provider>
        </AuthContextProvider>
    ) : (
        <LoadingView text="Initializing..." />
    );
};

export default AppContextProvider;
