import { isAfter, subDays } from "date-fns";
import { ProjectParticipant } from "../api/graphQL/queryModels";

const ParticipantSorter = {
    filterAndSortProjectParticipants: (input: ProjectParticipant[]): ProjectParticipant[] => {
        const projectHasActiveParticipantsWithTimeContributed = input.some((p) => !!p.lastTimeEntryDate && p.contributedTimeInMinutes > 0);
        const fortyFiveDaysAgo = subDays(new Date(), 45);
        return input
            .filter((p: ProjectParticipant) => p.contributedTimeInMinutes > 5 * 60)
            .sort((a: ProjectParticipant, b: ProjectParticipant) => {
                // From Projects: Best to show participants by who was last on the project, as well those who worked longest for people who may have questions
                const aIsActive = !!a.lastTimeEntryDate && isAfter(new Date(a.lastTimeEntryDate), fortyFiveDaysAgo);
                const bIsActive = !!b.lastTimeEntryDate && isAfter(new Date(b.lastTimeEntryDate), fortyFiveDaysAgo);
                if (aIsActive && !bIsActive) {
                    return -1;
                } else if (!aIsActive && bIsActive) {
                    return 1;
                }
                if (projectHasActiveParticipantsWithTimeContributed && a.person.isEnabled && !b.person.isEnabled) {
                    return -1;
                } else if (projectHasActiveParticipantsWithTimeContributed && !a.person.isEnabled && b.person.isEnabled) {
                    return 1;
                }
                return b.contributedTimeInMinutes - a.contributedTimeInMinutes;
            });
    }
};

export default ParticipantSorter;
