import * as React from "react";
import PageContent from "./PageContent";
import ConstrainedPageContent from "./ConstrainedPageContent";

interface PageProps {
    noBackground?: boolean;
    noMarginTop?: boolean;
    noSidePadding?: boolean;
    className?: string;
    children: React.ReactNode;
}

const Page = (props: PageProps) => {
    const { noBackground = false, className = "", noMarginTop = false, noSidePadding = false } = props;
    const additionalClasses = `${noBackground ? "no-bg" : ""} ${noMarginTop ? "no-margin-top" : ""} ${
        noSidePadding ? "no-side-padding" : ""
    } ${className}`;

    return (
        <div className={`page ${additionalClasses}`}>
            <PageContent>
                <ConstrainedPageContent>{props.children}</ConstrainedPageContent>
            </PageContent>
        </div>
    );
};

export default Page;
