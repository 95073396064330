import * as React from "react";
import { Snackbar, makeStyles, Theme } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useNotifications } from "./NotificationContextProvider";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

const Notifications = () => {
    const classes = useStyles();
    const { message, severity, clearNotification } = useNotifications();
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        if (message) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [message, severity]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        clearNotification();
    };

    return (
        <div className={classes.root}>
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleClose} severity={severity} icon={false}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default React.memo(Notifications);
