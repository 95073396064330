import { BeaconStoryOutcome } from "../../../api/graphQL/queryModels";

const StoryOutcomeSentimentFormatter = {
    formatSentimentCaption: (sentimentName: keyof BeaconStoryOutcome, sentimentValue: number): string => {
        if (sentimentName === "difficultySentiment") {
            if (sentimentValue < 3) {
                return "Easy";
            } else if (sentimentValue < 6) {
                return "Medium";
            } else if (sentimentValue < 9) {
                return "Difficult";
            } else {
                return "Impossible";
            }
        } else if (sentimentName === "accomplishmentSentiment") {
            if (sentimentValue < 3) {
                return "We didn't meet our own expectations";
            } else if (sentimentValue < 6) {
                return "We accomplished some of our goals, but wish we did more";
            } else if (sentimentValue < 9) {
                return "We accomplished most of what we set out to do";
            } else {
                return "We couldn't have possibly done more";
            }
        } else if (sentimentName === "teamCohesivenessSentiment") {
            if (sentimentValue < 3) {
                return "The team struggled with conflict throughout";
            } else if (sentimentValue < 6) {
                return "We had to go through some rough patches before we started to gel";
            } else if (sentimentValue < 9) {
                return "The team worked together well throughout and resolved conflict quickly";
            } else {
                return "We are one being";
            }
        } else if (sentimentName === "clientSentiment") {
            if (sentimentValue < 3) {
                return "The client was not happy";
            } else if (sentimentValue < 6) {
                return "We had some good times, some bad times, but in the end the client was happy";
            } else if (sentimentValue < 9) {
                return "We solved issues quickly and the client was mostly happy throughout";
            } else {
                return "The client hasn't stopped smiling since the kickoff";
            }
        } else if (sentimentName === "stressLevelSentiment") {
            if (sentimentValue < 3) {
                return "We were zen";
            } else if (sentimentValue < 6) {
                return "We were able to deliver without much stress";
            } else if (sentimentValue < 9) {
                return "It was rough for a bit, but now we're good";
            } else {
                return "The team is burned out";
            }
        }
        return "";
    }
};

export default StoryOutcomeSentimentFormatter;
