import * as React from "react";
import { useParams } from "react-router-dom";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery, useMutation } from "@apollo/client";

import { ConstrainedPageContent, Page, PageContent, StretchedContent } from "./components/layout";
import { StoryComments, StoryHeader, StoryInfo, StoryLinkedItems } from "./components/stories";
import { useUserContext } from "./components/auth/UserContextProvider";
import { GET_BEACON_STORY } from "../api/graphQL/queries";
import { BeaconStory, BeaconStoryCommentList } from "../api/graphQL/queryModels";
import { APPEND_BEACON_STORY_COMMENT } from "../api/graphQL/mutations";
import { useNotifications } from "./components/notifications/NotificationContextProvider";
import DocumentTitle from "./components/DocumentTitle";

const useStyles = makeStyles((theme) => ({
    linkedItems: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    }
}));

const StoryDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const { setNotification } = useNotifications();
    const classes = useStyles();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { data, loading, error } = useQuery<{ beaconStory: BeaconStory }>(GET_BEACON_STORY, {
        variables: {
            id
        }
    });

    const story = data?.beaconStory;

    const isFetching = !story || loading;

    const { user: currentUser } = useUserContext();

    const [appendBeaconStoryComment, { error: appendBeaconStoryCommentError }] = useMutation<{
        beaconStoryCommentAppend: BeaconStoryCommentList;
    }>(APPEND_BEACON_STORY_COMMENT);

    const saveComment = async (comment: string) => {
        await appendBeaconStoryComment({
            variables: {
                beaconStoryComment: {
                    storyId: id,
                    text: comment
                }
            }
        });
    };

    React.useEffect(() => {
        if (error?.message || appendBeaconStoryCommentError?.message) {
            setNotification(error?.message || appendBeaconStoryCommentError?.message, "error");
        }
    }, [error, appendBeaconStoryCommentError, setNotification]);

    if (isFetching) {
        return (
            <Page noMarginTop>
                <PageContent>
                    <StretchedContent>
                        <Skeleton variant="rect" height={200} />
                    </StretchedContent>
                    <ConstrainedPageContent>
                        <Grid container spacing={2}>
                            <Grid item />
                            <Grid item xs={12} className="avatar-placeholders">
                                <Skeleton variant="circle" width={48} height={48} />
                                <Skeleton variant="circle" width={48} height={48} />
                                <Skeleton variant="circle" width={48} height={48} />
                                <Skeleton variant="circle" width={48} height={48} />
                                <Skeleton variant="circle" width={48} height={48} />
                                <Skeleton variant="circle" width={48} height={48} />
                            </Grid>
                            <Grid item xs={12} className="image-placeholders">
                                <Skeleton variant="rect" width={96} height={128} />
                                <Skeleton variant="rect" width={96} height={128} />
                                <Skeleton variant="rect" width={96} height={128} />
                                <Skeleton variant="rect" width={96} height={128} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    <Skeleton variant="text" width="45%" />
                                </Typography>
                                <Typography variant="body1">
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" width="30%" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </ConstrainedPageContent>
                </PageContent>
            </Page>
        );
    }

    return (
        <>
            <DocumentTitle pageTitle={story?.title} />
            <Page noMarginTop>
                <PageContent>
                    {story && (
                        <StretchedContent>
                            <StoryHeader story={story} />
                        </StretchedContent>
                    )}
                    {story && (
                        <ConstrainedPageContent>
                            <StoryInfo story={story} />
                        </ConstrainedPageContent>
                    )}
                    <div className={classes.linkedItems}>
                        <Typography variant="h5" gutterBottom>
                            Linked Items
                        </Typography>
                        {story && <StoryLinkedItems story={story} />}
                    </div>
                    {!!currentUser && !!story && (
                        <ConstrainedPageContent>
                            <StoryComments comments={story.commentList?.comments || []} saveComment={saveComment} />
                        </ConstrainedPageContent>
                    )}
                </PageContent>
            </Page>
        </>
    );
};

export default StoryDetailPage;
