import { useMutation, useQuery } from "@apollo/client";
import { Card, CardContent, Chip, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { BeaconItemResumeGroupMappingInput } from "../api/graphQL/mutationModels";
import { UPDATE_BEACON_ITEM_RESUME_GROUP_MAPPING } from "../api/graphQL/mutations";
import { GET_BEACON_ITEMS_FOR_MAPPING, GET_BEACON_ITEM_RESUME_GROUPS } from "../api/graphQL/queries";
import { BeaconItem, BeaconItemResumeGroup, GraphQlConnection } from "../api/graphQL/queryModels";
import DocumentTitle from "./components/DocumentTitle";
import { DraggableItemTypes } from "./components/items/DraggableItemTypes";
import { Page } from "./components/layout";

const useStyles = makeStyles((theme) => ({
    resumeGroup: { width: "100%" },
    itemsList: {
        "& > *": {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        }
    },
    dropZone: {
        background: theme.palette.background.default,
        minHeight: 50,
        padding: theme.spacing(1),
        borderRadius: 4,
        marginTop: theme.spacing(0.5),
        "&.isOver": {
            background: theme.palette.background.paper
        }
    },
    container: {
        height: "calc(100vh - 180px)",
        display: "flex",
        flex: "0 200px"
    },
    containerLeft: {
        overflowY: "auto",
        padding: theme.spacing(2),
        width: 300,
        flexShrink: 0
    },
    containerRight: {
        overflowY: "auto",
        padding: theme.spacing(2)
    }
}));

type MinimalBeaconItem = Pick<BeaconItem, "id" | "name"> & { resumeGroup: BeaconItemResumeGroup };

const DraggableBeaconItem: React.FunctionComponent<{ item: MinimalBeaconItem }> = (props) => {
    const { item } = props;
    const [, drag] = useDrag(() => ({ type: DraggableItemTypes.BeaconItem, item }));
    return <Chip ref={drag} draggable="true" label={item.name} />;
};

const ResumeGroupCard: React.FunctionComponent<{
    resumeGroup?: BeaconItemResumeGroup;
    items: MinimalBeaconItem[];
}> = (props) => {
    const classes = useStyles();
    const { resumeGroup, items } = props;

    const [updateMapping] = useMutation<{ mapping: BeaconItemResumeGroupMappingInput }>(UPDATE_BEACON_ITEM_RESUME_GROUP_MAPPING);

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: DraggableItemTypes.BeaconItem,
            drop(item: MinimalBeaconItem, monitor) {
                const didDrop = monitor.didDrop();
                if (didDrop) {
                    return;
                }
                updateMapping({ variables: { mapping: { beaconItemId: item.id, resumeGroupId: resumeGroup?.id || null } } });
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true })
            })
        }),
        [resumeGroup]
    );

    return (
        <Card className={classes.resumeGroup}>
            <CardContent>
                <Typography variant="body1">{resumeGroup?.name}</Typography>
                <div className={`${classes.dropZone} ${isOver ? "isOver" : ""} ${classes.itemsList}`} ref={drop}>
                    {items.map((i) => {
                        return <DraggableBeaconItem key={i.id} item={i} />;
                    })}
                </div>
            </CardContent>
        </Card>
    );
};

const ResumeGroupsPage: React.FunctionComponent = () => {
    const classes = useStyles();

    const { data: resumeGroupData } = useQuery<{
        beaconItemResumeGroups: GraphQlConnection<BeaconItemResumeGroup>;
    }>(GET_BEACON_ITEM_RESUME_GROUPS);

    const { data: itemsData } = useQuery<{
        beaconItems: GraphQlConnection<MinimalBeaconItem>;
    }>(GET_BEACON_ITEMS_FOR_MAPPING, {
        variables: {
            first: 1000,
            after: null
        },
        notifyOnNetworkStatusChange: true
    });

    const resumeGroups = resumeGroupData?.beaconItemResumeGroups.edges.map((e) => e.node) || [];
    const items = itemsData?.beaconItems.edges.map((e) => e.node) || [];

    const resumeGroupMap: { [key: string]: { resumeGroup: BeaconItemResumeGroup; items: MinimalBeaconItem[] } } = {};
    for (const resumeGroup of resumeGroups) {
        resumeGroupMap[resumeGroup.id] = {
            resumeGroup,
            items: []
        };
    }

    const unmappedItems: MinimalBeaconItem[] = [];
    for (const item of items) {
        if (item.resumeGroup) {
            resumeGroupMap[item.resumeGroup.id].items.push(item);
        } else {
            unmappedItems.push(item);
        }
    }

    return (
        <>
            <DocumentTitle pageTitle="Resume Groups" />
            <Page>
                <div className={classes.container}>
                    <div className={classes.containerLeft}>
                        <ResumeGroupCard items={unmappedItems} />
                    </div>
                    <div className={classes.containerRight}>
                        <Grid container spacing={4}>
                            {resumeGroups.map((g) => {
                                const map = resumeGroupMap[g.id];
                                return (
                                    <Grid key={g.id} item xs={12}>
                                        <ResumeGroupCard resumeGroup={g} items={map.items} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            </Page>
        </>
    );
};

export default ResumeGroupsPage;
