import React from "react";
import ItemsDataQualityTable from "./ItemsDataQualityTable";
import { useQuery } from "@apollo/client";
import { BeaconItemDataQualityReport, BeaconItemDataQualityReportConnection } from "../../../api/graphQL/queryModels";
import { GET_BEACON_ITEMS_DATA_QUALITY } from "../../../api/graphQL/queries";
const pageSize = 20;
const ItemsDataQualityView: React.FunctionComponent = () => {
    const { data, loading, fetchMore } = useQuery<{ dataQuality: BeaconItemDataQualityReportConnection }>(GET_BEACON_ITEMS_DATA_QUALITY, {
        variables: { first: 20 }
    });
    const itemReports = (data?.dataQuality.beaconItems.edges.map((e) => e.node) as BeaconItemDataQualityReport[]) || [];
    const handleLoadMore = () => {
        fetchMore({
            variables: { first: pageSize, after: data?.dataQuality.beaconItems.pageInfo.endCursor },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.dataQuality.beaconItems.edges;
                return newEdges?.length
                    ? {
                          dataQuality: {
                              beaconItems: {
                                  __typename: previousResult.dataQuality.beaconItems.__typename,
                                  edges: [...previousResult.dataQuality.beaconItems.edges, ...newEdges],
                                  pageInfo: fetchMoreResult.dataQuality.beaconItems.pageInfo
                              }
                          }
                      }
                    : previousResult;
            }
        });
    };
    return (
        <ItemsDataQualityTable
            reports={itemReports}
            loading={loading}
            hasMore={!!data?.dataQuality.beaconItems.pageInfo.hasNextPage}
            fetchMore={handleLoadMore}
        />
    );
};
export default ItemsDataQualityView;
