import * as React from "react";

import { Grid } from "@material-ui/core";

const FormControl = (props: { children: React.ReactNode }) => {
    return (
        <Grid container className="form-control">
            <Grid item xs={12}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default FormControl;
