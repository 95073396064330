import * as React from "react";
import { Grid } from "@material-ui/core";

interface FormProps {
    fullWidth?: boolean;
    children: React.ReactNode;
    onSubmit?: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
}

const Form = (props: FormProps) => {
    const { fullWidth = false } = props;

    return (
        <form className="form" onSubmit={props.onSubmit}>
            <Grid container>
                <Grid item xs={12} sm={fullWidth ? 12 : 8} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
                    <Grid container spacing={8}>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
