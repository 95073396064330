import * as React from "react";
import { useUserContext } from "./pages/components/auth/UserContextProvider";
import { AppHeader as LanternAppHeader } from "@devfacto/react-components";
import { useAppContext } from "./AppContextProvider";

const AppHeader = () => {
    const { user } = useUserContext();
    const { context } = useAppContext();
    const appsUri = context?.settings?.relatedAppsUri;

    return (
        <LanternAppHeader
            appName="Beacon"
            appsUri={appsUri}
            user={
                user
                    ? {
                          id: user.id,
                          photoUri: user.person.photos.length ? user.person.photos[0].uri : undefined
                      }
                    : undefined
            }
        ></LanternAppHeader>
    );
};

export default AppHeader;
