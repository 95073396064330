import * as React from "react";

import { Grid } from "@material-ui/core";
import StoryListItem from "./StoryListItem";
import { BeaconStory } from "../../../api/graphQL/queryModels";

interface StoryListProps {
    stories: BeaconStory[];
}

const StoryList = (props: StoryListProps) => {
    const { stories = [] } = props;
    return (
        <Grid container spacing={3} justifyContent="space-evenly">
            {(stories || []).map((story) => {
                return (
                    <Grid key={story.id}>
                        <StoryListItem story={story} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default StoryList;
