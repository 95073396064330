import * as React from "react";
import { Markdown } from "../misc";
import { BeaconItem } from "../../../api/graphQL/queryModels";

interface ItemDetailsProps {
    beacon: BeaconItem;
}

const ItemDetails = (props: ItemDetailsProps) => {
    const { beacon } = props;

    return (
        <div className="beacon-info">
            <div className="beacon-info-description">
                <Markdown>{beacon.description || ""}</Markdown>
            </div>
        </div>
    );
};

export default ItemDetails;
