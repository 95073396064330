import { gql } from "@apollo/client";
import { BEACON_ITEM_FIELDS_FRAGMENT, BEACON_STORY_CORE_FIELDS_FRAGMENT } from "./fragments";

export const UPSERT_BEACON_ITEM = gql`
    ${BEACON_ITEM_FIELDS_FRAGMENT}
    mutation UpsertBeaconItem($beaconItem: BeaconItemInput!) {
        beaconItemUpsert(beaconItem: $beaconItem) {
            ...BeaconItemCoreFields
        }
    }
`;

export const UPDATE_BEACON_ITEM_RESUME_GROUP_MAPPING = gql`
    mutation Update($mapping: BeaconItemResumeGroupMappingInput!) {
        beaconItemResumeGroupMappingUpdate(mapping: $mapping) {
            id
            name
            resumeGroup {
                id
                name
            }
        }
    }
`;

export const UPSERT_BEACON_STORY = gql`
    ${BEACON_STORY_CORE_FIELDS_FRAGMENT}
    mutation UpsertBeaconStory($beaconStory: BeaconStoryInput!) {
        beaconStoryUpsert(beaconStory: $beaconStory) {
            ...BeaconStoryCoreFields
        }
    }
`;

export const UPSERT_BEACON_TAG = gql`
    mutation UpsertBeaconTag($beaconTag: BeaconTagInput!) {
        beaconTagUpsert(beaconTag: $beaconTag) {
            id
            name
            isDeleted
        }
    }
`;

export const APPEND_BEACON_STORY_COMMENT = gql`
    mutation AppendBeaconStoryComment($beaconStoryComment: BeaconStoryCommentInput!) {
        beaconStoryCommentAppend(beaconStoryComment: $beaconStoryComment) {
            id
            comments {
                id
                author {
                    id
                    givenName
                    surname
                    emailAddress
                    photos {
                        uri
                        size
                    }
                }
                text
            }
        }
    }
`;
