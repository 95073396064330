import * as React from "react";
import { Chip } from "@material-ui/core";
import { BeaconItemTag } from "../../../api/graphQL/queryModels";

interface TagListProps {
    tags: BeaconItemTag[];
    size?: "medium" | "small";
}

const TagList = (props: TagListProps) => {
    const { tags, size = "small" } = props;
    return (
        <div className="tag-list">
            {tags.map((t) => {
                return <Chip size={size} key={t.id} label={t.name} className="tag-list-chip" />;
            })}
        </div>
    );
};

export default TagList;
