import * as React from "react";
import { Grid } from "@material-ui/core";
import StoryGalleryImage from "./StoryGalleryImage";
import { BeaconStoryImageMetadata } from "../../../api/graphQL/queryModels";

interface StoryGalleryProps {
    images: BeaconStoryImageMetadata[];
}

const StoryGallery = (props: StoryGalleryProps) => {
    const { images } = props;
    const galleryImages = (images || []).filter((i) => i.showInGallery);

    return galleryImages.length ? (
        <Grid container spacing={2} className="story-gallery">
            {galleryImages.map((image) => {
                return (
                    <Grid item xs={3} sm={2} key={image.url}>
                        <StoryGalleryImage image={image} gallery={galleryImages} />
                    </Grid>
                );
            })}
        </Grid>
    ) : null;
};

export default StoryGallery;
