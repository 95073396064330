import * as React from "react";
import { useAuth } from "../../pages/components/auth/AuthProvider";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const apolloClient = new ApolloClient({
    cache: new InMemoryCache()
});

const AuthenticatedApolloProvider = (props: { uri: string; children: React.ReactNode }) => {
    const { getAccessToken } = useAuth();
    const httpLink = createHttpLink({
        uri: props.uri
    });

    const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = getAccessToken ? await getAccessToken() : null;
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ""
            }
        };
    });

    apolloClient.setLink(authLink.concat(httpLink));

    return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
