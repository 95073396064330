import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import React from "react";
import { Waypoint } from "react-waypoint";
import { BeaconStoryDataQualityReport } from "../../../api/graphQL/queryModels";
import StoryDataQualityTableRow from "./StoryDataQualityTableRow";

const useStyles = makeStyles((theme) => ({
    root: { width: "100%", overflow: "hidden" },
    tableContainer: { minHeight: 600, height: "calc(100vh - 200px)" },
    tableHeader: {
        "& > tr > th": { backgroundColor: theme.palette.background.paper }
    },
    centered: {
        textAlign: "center"
    },
    left: {
        textAlign: "left"
    },
    right: {
        textAlign: "right"
    }
}));

const StoriesDataQualityTable: React.FunctionComponent<{
    reports: BeaconStoryDataQualityReport[];
    loading: boolean;
    hasMore: boolean;
    fetchMore: () => void;
}> = (props) => {
    const { reports, loading, hasMore, fetchMore } = props;

    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell className={classes.left}>Score</TableCell>
                            <TableCell className={classes.centered}>Title</TableCell>
                            <TableCell className={classes.centered}>Preview</TableCell>
                            <TableCell className={classes.centered}>Description</TableCell>
                            <TableCell className={classes.centered}>Project</TableCell>
                            <TableCell className={classes.centered}>Project Successes</TableCell>
                            <TableCell className={classes.centered}>Project Failures</TableCell>
                            <TableCell className={classes.centered}>Project Improvements</TableCell>
                            <TableCell className={classes.centered}>Header Image</TableCell>
                            <TableCell className={classes.centered}>Images</TableCell>
                            <TableCell className={classes.centered}>Items</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading &&
                            [1, 2, 3, 4, 5, 6, 7].map((i) => {
                                return <StoryDataQualityTableRow key={i} />;
                            })}
                        {!loading &&
                            reports.map((report, i) => {
                                return <StoryDataQualityTableRow key={`${report.beaconStory.id}-${i}`} report={report} />;
                            })}
                        {!loading && hasMore && (
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    <CircularProgress />
                                    <Waypoint onEnter={fetchMore} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default StoriesDataQualityTable;
