import * as React from "react";
import { MenuItem, Button, Grid, TextField } from "@material-ui/core";

import { FormControl, FormField, FormSection } from "../forms";
import { MarkdownEditor } from "../misc";
import { BeaconStoryItemSelectionInput, BeaconStoryLinkedItemOutcomeInput } from "../../../api/graphQL/mutationModels";
import { BeaconItem, BeaconStorySelectedItemOutcomeSentiment } from "../../../api/graphQL/queryModels";

interface StoryEditorLinkedItemsSectionProps {
    value: BeaconStoryItemSelectionInput[];
    itemSummaries: BeaconItem[];
    onChange: (value: BeaconStoryItemSelectionInput[]) => unknown;
}

const StoryEditorLinkedItemsSection = (props: StoryEditorLinkedItemsSectionProps) => {
    const { value, itemSummaries } = props;

    const onAddItem = () => {
        props.onChange(
            props.value.concat({
                selectedItemId: null,
                consideredAlternativeItemIds: [],
                title: "",
                explanation: "",
                outcome: {
                    justification: "",
                    sentiment: BeaconStorySelectedItemOutcomeSentiment.Neutral
                }
            })
        );
    };

    const onDeleteItem = (index: number) => () => {
        props.onChange(props.value.filter((t, i) => i !== index));
    };

    const onItemChange = (propName: keyof BeaconStoryItemSelectionInput, index: number) => (event: unknown) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = propName === "explanation" ? event : (event as any).target.value;
        const newItems = props.value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    [propName]: value
                };
            }
            return item;
        });
        props.onChange(newItems);
    };

    const onItemOutcomeChange = (propName: keyof BeaconStoryLinkedItemOutcomeInput, index: number) => (event: unknown) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = propName === "justification" ? event : (event as any).target.value;
        const newItems = props.value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    outcome: {
                        ...item.outcome,
                        [propName]: value
                    }
                };
            }
            return item;
        });
        props.onChange(newItems);
    };

    return (
        <FormSection title="Linked items" fullWidth>
            {value.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        {i > 0 ? (
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                        ) : null}
                        <Grid item container xs={12} sm={6}>
                            <FormField fullWidth>
                                <TextField
                                    id="select-item"
                                    select
                                    label="Selected Item"
                                    value={item.selectedItemId && itemSummaries.length ? item.selectedItemId : ""}
                                    onChange={onItemChange("selectedItemId", i)}
                                    variant="outlined"
                                    fullWidth
                                    required
                                >
                                    {itemSummaries.map((beacon) => {
                                        return (
                                            <MenuItem key={beacon.id} value={beacon.id}>
                                                {beacon.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormField>
                        </Grid>
                        <Grid item container xs={12} sm={6}>
                            <FormField fullWidth>
                                <TextField
                                    id="select-alternatives"
                                    select
                                    label="Considered Alternatives"
                                    SelectProps={{
                                        multiple: true
                                    }}
                                    value={item.consideredAlternativeItemIds}
                                    onChange={onItemChange("consideredAlternativeItemIds", i)}
                                    variant="outlined"
                                    fullWidth
                                >
                                    {itemSummaries.map((beacon) => {
                                        return (
                                            <MenuItem key={beacon.id} value={beacon.id}>
                                                {beacon.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormField>
                        </Grid>
                        <FormField fullWidth>
                            <FormControl>
                                <MarkdownEditor
                                    labelText="Explanation"
                                    value={item.explanation}
                                    onChange={onItemChange("explanation", i)}
                                />
                            </FormControl>
                        </FormField>

                        <FormField>
                            <TextField
                                id="select-outcome"
                                select
                                label="Outcome"
                                value={item.outcome.sentiment}
                                onChange={onItemOutcomeChange("sentiment", i)}
                                variant="outlined"
                            >
                                <MenuItem value={BeaconStorySelectedItemOutcomeSentiment.Neutral}>
                                    {BeaconStorySelectedItemOutcomeSentiment.Neutral}
                                </MenuItem>
                                <MenuItem value={BeaconStorySelectedItemOutcomeSentiment.Positive}>
                                    {BeaconStorySelectedItemOutcomeSentiment.Positive}
                                </MenuItem>
                                <MenuItem value={BeaconStorySelectedItemOutcomeSentiment.Negative}>
                                    {BeaconStorySelectedItemOutcomeSentiment.Negative}
                                </MenuItem>
                                <MenuItem value={BeaconStorySelectedItemOutcomeSentiment.Mixed}>
                                    {BeaconStorySelectedItemOutcomeSentiment.Mixed}
                                </MenuItem>
                            </TextField>
                        </FormField>
                        <FormField fullWidth>
                            <FormControl>
                                <MarkdownEditor
                                    labelText="Justification"
                                    value={item.outcome.justification}
                                    onChange={onItemOutcomeChange("justification", i)}
                                />
                            </FormControl>
                        </FormField>
                        <FormField>
                            <Button className="delete-btn" onClick={onDeleteItem(i)}>
                                Delete Item
                            </Button>
                        </FormField>
                    </React.Fragment>
                );
            })}
            <Grid item xs={12}>
                <Button color="primary" onClick={onAddItem}>
                    Add item
                </Button>
            </Grid>
        </FormSection>
    );
};

export default StoryEditorLinkedItemsSection;
