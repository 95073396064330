import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const BeaconCreateButton = () => {
    return (
        <Button component={RouterLink} to={`/items/new`} variant="contained" startIcon={<AddIcon />}>
            New Item
        </Button>
    );
};

export default BeaconCreateButton;
