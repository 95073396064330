import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { unregisterServiceWorker } from "./registerServiceWorker";
import AppContext from "./AppContextProvider";

import RouteList from "./RouteList";

import "css.escape";
import "./styles/index.scss";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <DndProvider backend={HTML5Backend}>
            <AppContext>
                <RouteList />
            </AppContext>
        </DndProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// Service worker is problematic for new deploys
// until we add some versioned cache busting
unregisterServiceWorker();
