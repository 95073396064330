import * as React from "react";

import {
    Card,
    ButtonBase,
    CardMedia,
    Typography,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Snackbar,
    Container,
    Button
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { BeaconStoryImageMetadata } from "../../../api/graphQL/queryModels";

export interface StoryGalleryImageState {
    open: boolean;
    slide: number;
}

export interface StoryGalleryImageProps {
    image: BeaconStoryImageMetadata;
    gallery: BeaconStoryImageMetadata[];
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...(props as object)} />;
});

const StoryGalleryImage = (props: StoryGalleryImageProps) => {
    const [slide, setSlide] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const { image, gallery } = props;
    const galleryImage = gallery[slide];

    const initializeSlide = () => {
        for (let i = 0; i < props.gallery.length; i++) {
            if (props.gallery[i].url === props.image.url) {
                setSlide(i);
            }
        }
    };
    const handleLeft = () => {
        if (slide === 0) {
            /* empty */
        } else {
            setSlide(slide - 1);
        }
    };

    const handleRight = () => {
        if (slide === props.gallery.length - 1) {
            /* empty */
        } else {
            setSlide(slide + 1);
        }
    };
    const handleKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowRight") {
            handleRight();
        } else if (event.key === "ArrowLeft") {
            handleLeft();
        } else if (event.key === "Escape") {
            handleClose();
        }
    };

    const handleOpen = () => {
        setOpen(true);
        initializeSlide();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const imageUrl = image.thumbnailUrl || image.optimizedUrl || image.url;

    return (
        <>
            <Card className={`attachment-preview`}>
                {imageUrl ? (
                    <div className="attachment-preview-img-wrapper">
                        <ButtonBase className="attachment-preview-img-wrapper" focusRipple onClick={handleOpen}>
                            <CardMedia className="attachment-preview-img" image={imageUrl} title={image.title || ""} />
                        </ButtonBase>
                    </div>
                ) : (
                    <div className="attachment-preview-img-wrapper">
                        <div className="uploading-bg">
                            <Typography className="uploading-msg" variant="h5" component="div">
                                Uploading...
                            </Typography>
                        </div>
                    </div>
                )}
            </Card>
            <Dialog
                className="working-attachment-preview-dialog"
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onKeyDown={(e) => handleKey(e)}
            >
                <AppBar color="primary">
                    <Toolbar className="attachment-preview-dialog-toolbar">
                        <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                            <i className="material-icons">close</i>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className="attachment-preview-dialog-title">
                            {galleryImage.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container>
                    <div className="attachment-preview-dialog-content">
                        {
                            <img
                                src={galleryImage.optimizedUrl || galleryImage.url}
                                alt={galleryImage.description || galleryImage.title || ""}
                            />
                        }
                    </div>
                    {gallery.length === 1 ? null : (
                        <>
                            <Button onClick={handleLeft} startIcon={<ArrowBackIcon />} />
                            <Button onClick={handleRight} startIcon={<ArrowForwardIcon />} />
                        </>
                    )}
                    <Snackbar open={!!galleryImage.description && open} message={galleryImage.description} />
                </Container>
            </Dialog>
        </>
    );
};

export default StoryGalleryImage;
