import * as React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";

interface FormSectionProps {
    title?: string | JSX.Element;
    fullWidth?: boolean;
    children: React.ReactNode;
}

const FormSection = (props: FormSectionProps) => {
    return (
        <Grid item xs={12} className="form-section">
            <Paper className="form-section-paper">
                {props.title ? (
                    <Typography variant="h5" gutterBottom>
                        {props.title}
                    </Typography>
                ) : null}
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={props.fullWidth ? 12 : 8} md={props.fullWidth ? 12 : 6} lg={props.fullWidth ? 12 : 4}>
                        <Grid container spacing={4}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FormSection;
