import * as React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

import { FormControl, FormField } from "../forms";

interface StoryEditorPublishSectionProps {
    isPublished: boolean;
    onPublishedChange: (isPublished: boolean) => unknown;
}

const StoryEditorPublishSection = (props: StoryEditorPublishSectionProps) => {
    const { isPublished, onPublishedChange } = props;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPublishedChange(event.target.checked);
    };

    return (
        <FormField>
            <FormControl>
                <FormControlLabel control={<Switch checked={isPublished} onChange={onChange} color="primary" />} label="Publish?" />
            </FormControl>
        </FormField>
    );
};

export default StoryEditorPublishSection;
