import * as React from "react";
import { TextField } from "@material-ui/core";
import { FormSection, FormField, FormControl } from "../forms";
import { MarkdownEditor } from "../misc";
import { nameof } from "ts-simple-nameof";
import { BeaconStory } from "../../../api/graphQL/queryModels";

interface StoryEditorAboutSectionProps {
    title: string;
    previewDescription: string;
    description: string;
    setFieldValue: (fieldName: string, fieldValue: unknown) => unknown;
    handleChange: (
        eventOrPath: string | React.ChangeEvent<unknown>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<unknown>) => void);
}

const StoryEditorAboutSection = (props: StoryEditorAboutSectionProps) => {
    const { title, previewDescription, description, handleChange, setFieldValue } = props;
    return (
        <FormSection title="About" fullWidth>
            <FormField>
                <FormControl>
                    <TextField
                        name={nameof<BeaconStory>((s) => s.title)}
                        label="Title"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
            </FormField>
            <FormField>
                <FormControl>
                    <TextField
                        name={nameof<BeaconStory>((s) => s.previewDescription)}
                        label="Preview Description"
                        variant="outlined"
                        fullWidth
                        value={previewDescription}
                        onChange={handleChange}
                    />
                </FormControl>
            </FormField>
            <FormField fullWidth>
                <FormControl>
                    <MarkdownEditor
                        labelText="Description"
                        value={description}
                        onChange={(value: string) => {
                            setFieldValue(
                                nameof<BeaconStory>((s) => s.description),
                                value
                            );
                        }}
                    />
                </FormControl>
            </FormField>
        </FormSection>
    );
};

export default StoryEditorAboutSection;
