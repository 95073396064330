import * as React from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import { Markdown } from "../misc";
import { SimpleBeaconListItem } from "../items";
import { TagList } from "../tags";
import StoryLinkedItemOutcomeSentimentBadge from "./StoryLinkedItemOutcomeSentimentBadge";
import { BeaconStory } from "../../../api/graphQL/queryModels";

interface ComponentProps {
    story: BeaconStory;
}

const useStyles = makeStyles((theme) => ({
    sentimentContainer: {
        display: "flex"
    }
}));

const StoryLinkedItems = (props: ComponentProps) => {
    const { story } = props;
    const classes = useStyles();

    return (
        <div className="story-linked-items">
            {story.selectedItems.map((selectedItem) => {
                return (
                    <Paper key={selectedItem.selectedItem.id} className="story-linked-items-item">
                        <div>
                            <Grid container spacing={3}>
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">Selected:</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.sentimentContainer}>
                                        <SimpleBeaconListItem selected={true} beacon={selectedItem.selectedItem} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {<TagList size="small" tags={selectedItem.selectedItem.tags || []} />}
                                    </Grid>
                                </Grid>
                                {selectedItem.consideredAlternativeItems.length ? (
                                    <Grid item container>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Considered:</Typography>
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            {selectedItem.consideredAlternativeItems.map((alternativeItem, index) => {
                                                return (
                                                    <Grid item key={alternativeItem.id}>
                                                        <SimpleBeaconListItem beacon={alternativeItem} selected={false} />
                                                        {index < selectedItem.consideredAlternativeItems.length - 1 && ", "}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">Explanation:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Markdown>{selectedItem.explanation}</Markdown>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">Outcome:</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.sentimentContainer}>
                                        <StoryLinkedItemOutcomeSentimentBadge sentiment={selectedItem.outcome.sentiment} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Markdown>{selectedItem.outcome.justification}</Markdown>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                );
            })}
        </div>
    );
};

export default StoryLinkedItems;
