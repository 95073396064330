import * as React from "react";
import { v4 as uuid } from "uuid";
import { List } from "@material-ui/core";

import EditableTagListItem from "./EditableTagListItem";
import { BeaconItemTag } from "../../../api/graphQL/queryModels";

interface EditableTagListProps {
    tags: BeaconItemTag[];
    saveTag: (tag: BeaconItemTag) => Promise<BeaconItemTag | null>;
}

const EditableTagList = (props: EditableTagListProps) => {
    const { tags } = props;

    const [newTag, setNewTag] = React.useState({
        id: uuid(),
        name: "",
        isDeleted: false
    } as BeaconItemTag);

    const handleSave = async (tag: BeaconItemTag) => {
        const result = await props.saveTag(tag);
        if (tag.id === newTag.id) {
            setNewTag({
                id: uuid(),
                name: "",
                isDeleted: false
            });
        }
        return result;
    };

    return (
        <List>
            {(tags || []).map((tag) => {
                return <EditableTagListItem key={tag.id} tag={tag} saveTag={handleSave} />;
            })}
            <EditableTagListItem tag={newTag} saveTag={handleSave} isNewTag />
        </List>
    );
};

export default EditableTagList;
