import * as React from "react";
import { PersonDetail } from "../../../api/graphQL/queryModels";
// import { ai } from "../telemetry/TelemetryService";
import { gql, useQuery } from "@apollo/client";

export interface ApplicationUser {
    id: string;
    appRoles: string[];
    person: PersonDetail;
}

const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            appRoles
            person {
                id
                emailAddress
                givenName
                surname
                fullName
                photos {
                    uri
                    size
                }
            }
        }
    }
`;

interface UserContextValue {
    user: ApplicationUser | null;
    isContentAdmin: boolean;
}

export const UserContext = React.createContext<UserContextValue>({ user: null, isContentAdmin: false });
export const useUserContext = () => React.useContext(UserContext);

const UserContextProvider = (props: { children: React.ReactNode }) => {
    const { data } = useQuery(GET_CURRENT_USER);

    const currentUser = data?.currentUser as ApplicationUser;

    React.useEffect(() => {
        if (currentUser) {
            // ai.appInsights?.setAuthenticatedUserContext(currentUser.id, currentUser.person.emailAddress);
        }
    }, [currentUser]);

    return (
        <UserContext.Provider
            value={{ user: currentUser, isContentAdmin: currentUser?.appRoles.some((role) => role === "BeaconContentAdmin") }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
