import * as React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import { Markdown } from "../misc";
import { PersonList } from "../people";
import StoryGallery from "./StoryGallery";
import StoryOutcomeSentimentBadgeList from "./StoryOutcomeSentimentBadgeList";
import { BeaconStory, ProjectParticipant } from "../../../api/graphQL/queryModels";
import { ParticipantSorter } from "../../../utils";

interface ComponentProps {
    story: BeaconStory;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(2)
    },
    markdownItem: {
        paddingTop: theme.spacing(1)
    },
    header: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    subHeader: {
        marginBottom: theme.spacing(2)
    }
}));

const StoryInfo = (props: ComponentProps) => {
    const { story } = props;
    const classes = useStyles();

    const participants: ProjectParticipant[] = ParticipantSorter.filterAndSortProjectParticipants(story.project?.participants || []);

    return (
        <div>
            <div className={classes.header}>
                <PersonList people={participants.map((p) => p.person)} showInactivePeople showText withLink truncate={false} />
            </div>

            <div>
                <StoryGallery images={story.images} />
            </div>
            <div className={classes.header}>
                <Typography variant="h5" gutterBottom>
                    Background
                </Typography>
                <Markdown>{story.description}</Markdown>
            </div>
            <div className={classes.header}>
                <Typography variant="h5" gutterBottom>
                    Outcome
                </Typography>
                <div className="story-item-outcome-inner">
                    <div>
                        <StoryOutcomeSentimentBadgeList outcome={story.outcome} />
                    </div>
                    <div>
                        {story.outcome.successNotes ? (
                            <Grid container className={classes.container}>
                                <Grid item xs={12} className={classes.header}>
                                    <Typography variant="h5">Successes</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.subHeader}>
                                    <Typography variant="body1">What went well?</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.markdownItem}>
                                    <Markdown>{story.outcome.successNotes}</Markdown>
                                </Grid>
                            </Grid>
                        ) : null}
                        {story.outcome.failureNotes ? (
                            <Grid container className={classes.container}>
                                <Grid item xs={12} className={classes.header}>
                                    <Typography variant="h5">Failures</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.subHeader}>
                                    <Typography variant="body1">What went poorly?</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.markdownItem}>
                                    <Markdown>{story.outcome.failureNotes}</Markdown>
                                </Grid>
                            </Grid>
                        ) : null}
                        {story.outcome.improvementNotes ? (
                            <Grid container className={classes.container}>
                                <Grid item xs={12} className={classes.header}>
                                    <Typography variant="h5">Learnings</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.subHeader}>
                                    <Typography variant="body1">What would we do differently?</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.markdownItem}>
                                    <Markdown>{story.outcome.improvementNotes}</Markdown>
                                </Grid>
                            </Grid>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoryInfo;
