import * as React from "react";
import { Grid } from "@material-ui/core";

import SearchResultListItem from "./SearchResultListItem";
import { BeaconContentSearchResult } from "../../../api/graphQL/queryModels";

interface SearchResultListProps {
    results: BeaconContentSearchResult[];
    onItemSelected: (selectedDocumentId: string, rank: number) => unknown;
}

const SearchResultList = (props: SearchResultListProps) => {
    const onItemSelected = (result: BeaconContentSearchResult, rank: number) => () => {
        props.onItemSelected(result.id, rank);
    };

    const { results } = props;

    return (
        <Grid container spacing={2}>
            {results
                ? results.map((result, index) => {
                      return (
                          <Grid key={result.id} item xs={12}>
                              <SearchResultListItem result={result} onClick={onItemSelected(result, index + 1)} />
                          </Grid>
                      );
                  })
                : null}
        </Grid>
    );
};

export default SearchResultList;
