import * as React from "react";
import { Link } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";
import { Markdown } from "../misc";
import { StoryLinkedItemOutcomeSentimentBadge } from "../stories";
import { BeaconStory, BeaconStorySelectedItem } from "../../../api/graphQL/queryModels";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            padding: theme.spacing(1)
        }
    },
    sentiment: {},
    explanation: {
        flexGrow: 1
    }
}));

interface ItemOutcomeProps {
    story: BeaconStory;
    selectedItem: BeaconStorySelectedItem;
}

const ItemOutcome = (props: ItemOutcomeProps) => {
    const classes = useStyles();
    const { selectedItem, story } = props;

    return (
        <div className={classes.root}>
            <div className={classes.sentiment}>
                <StoryLinkedItemOutcomeSentimentBadge sentiment={selectedItem.outcome.sentiment} />
            </div>
            <div className={classes.explanation}>
                <Typography variant="caption" color="textSecondary">
                    From the{" "}
                    <Link to={`/stories/${story.id}`} color="primary">
                        {story.title}
                    </Link>{" "}
                    story:
                </Typography>
                <Markdown>{selectedItem.outcome.justification}</Markdown>
            </div>
        </div>
    );
};

export default ItemOutcome;
