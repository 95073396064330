import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, CardActionArea, Typography } from "@material-ui/core";

import { TagList } from "../tags";
import { BeaconItem } from "../../../api/graphQL/queryModels";
import { makeStyles } from "@material-ui/core";
import { isAfter, subMinutes } from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    card: {
        borderWidth: 0,
        borderTopWidth: 7,
        borderStyle: "solid",
        borderColor: "rgba(0,0,0,0)",
        "&.AVOID": {
            borderColor: theme.palette.error.main
        },
        "&.just-added": {
            borderColor: theme.palette.success.main
        }
    },
    content: {
        height: 96
    },
    title: {
        fontSize: 20,
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word"
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    tags: {
        height: 32,
        overflow: "hidden"
    },
    archiveMarker: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: theme.spacing(1),
        background: theme.palette.error.main,
        borderBottomLeftRadius: 8,
        color: theme.palette.common.white
    }
}));

interface BeaconListItemProps {
    beacon: BeaconItem;
}

const BeaconListItem = (props: BeaconListItemProps) => {
    const classes = useStyles();
    const { beacon } = props;

    const now = new Date();
    const twoMinutesAgo = subMinutes(now, 2);
    const wasJustAdded = !!beacon.dateCreated && isAfter(new Date(beacon.dateCreated), twoMinutesAgo);
    return (
        <Card key={beacon.id} className={classes.root}>
            <CardActionArea component={RouterLink} to={`/items/${beacon.id}`}>
                <CardContent className={`${classes.card} ${beacon.endorsement} ${wasJustAdded ? "just-added" : ""}`}>
                    <div className={classes.content}>
                        {beacon.isArchived && <div className={classes.archiveMarker}>Archived</div>}
                        <Typography variant="h4" className={classes.title}>
                            {beacon.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.description}>
                            {beacon.previewDescription || "<no description>"}
                        </Typography>
                    </div>
                    <div className={classes.tags}>
                        <TagList size="small" tags={beacon.tags || []} />
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default BeaconListItem;
