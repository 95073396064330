import * as React from "react";
import { LinearProgress, Typography, Card, CardContent } from "@material-ui/core";
import StoryOutcomeSentimentFormatter from "./StoryOutcomeSentimentFormatter";
import { BeaconStoryOutcome } from "../../../api/graphQL/queryModels";

interface StoryOutcomeSentimentBadgeProps {
    title: string;
    sentimentPropertyName: keyof BeaconStoryOutcome;
    sentimentValue: number;
}

const StoryOutcomeSentimentBadge = (props: StoryOutcomeSentimentBadgeProps) => {
    const { title, sentimentPropertyName, sentimentValue } = props;
    return (
        <Card title={StoryOutcomeSentimentFormatter.formatSentimentCaption(sentimentPropertyName, sentimentValue)}>
            <CardContent>
                <Typography gutterBottom>{title}</Typography>
                <LinearProgress variant="determinate" value={sentimentValue * 10} color="primary" />
            </CardContent>
        </Card>
    );
};

export default StoryOutcomeSentimentBadge;
