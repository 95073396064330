import * as React from "react";
import { Link, makeStyles, Grid } from "@material-ui/core";
import { Avatar } from "../misc";
import { Person } from "../../../api/graphQL/queryModels";
import { AvatarGroup } from "@material-ui/lab";
import { useAppContext } from "../../../AppContextProvider";

type sizeVariant = "sm" | "md" | "lg" | "xl";

interface PersonListProps {
    people: Person[];
    showInactivePeople?: boolean;
    showText: boolean;
    withLink?: boolean;
    size?: sizeVariant;
    truncate: boolean;
}

const useStyles = makeStyles({
    avatarGroup: {
        "& .MuiAvatarGroup-avatar": {
            border: "none" // Remove border for avatars in AvatarGroup
        }
    }
});

const PersonList = (props: PersonListProps) => {
    const { people, showInactivePeople, size, showText, withLink, truncate } = props;
    const classes = useStyles();
    const { context } = useAppContext();

    const maxAvatars = 5;

    const renderAvatar = (teamMember: Person) => {
        const initials = (teamMember.givenName || "?").substr(0, 1) + (teamMember.surname || "?").substr(0, 1);
        if (!(teamMember.isEnabled || showInactivePeople)) {
            return null;
        }
        return (
            <div key={`person-list-${teamMember.id}`} className="person-list-person-avatar">
                {/* Render Avatar component based on withLink */}
                {withLink ? (
                    <Link target="_blank" href={`${context?.settings?.relatedAppsUri?.peopleApp}/people/${teamMember.id}`}>
                        <Avatar
                            size={size ? size : "lg"}
                            text={initials}
                            image={teamMember.photos.length ? teamMember.photos[0].uri : "/img/no_photo.png"}
                            isActive={teamMember.isEnabled}
                        />
                    </Link>
                ) : (
                    <Avatar
                        size={size ? size : "lg"}
                        text={initials}
                        image={teamMember.photos.length ? teamMember.photos[0].uri : "/img/no_photo.png"}
                        isActive={teamMember.isEnabled}
                        hasBorder={true}
                    />
                )}
            </div>
        );
    };

    if (truncate) {
        return (
            <AvatarGroup max={maxAvatars} className={classes.avatarGroup}>
                {people.map((teamMember, i) => (
                    <div key={`person-list-${i}`} className="person-list-person">
                        {renderAvatar(teamMember)}
                    </div>
                ))}
            </AvatarGroup>
        );
    }

    return (
        <Grid container className="person-list">
            {people.map((teamMember, i) => (
                <Grid item xs="auto" key={`person-list-${i}`} className="person-list-person">
                    {renderAvatar(teamMember)}
                    {showText && (
                        <div className="person-list-person-name">
                            <div className="person-list-person-name-first">{teamMember.givenName}</div>
                            <div className="person-list-person-name-last">{teamMember.surname}</div>
                        </div>
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default React.memo(PersonList);
