import * as React from "react";
import AppContextApi from "./AppContextApi";

export const ApiContext = React.createContext({
    appContext: AppContextApi
});

export const useAppContextApi = () => React.useContext(ApiContext);

const ApiContextProvider = (props: { children: React.ReactNode }) => {
    return (
        <ApiContext.Provider
            value={{
                appContext: AppContextApi
            }}
        >
            {props.children}
        </ApiContext.Provider>
    );
};

export default ApiContextProvider;
