import * as React from "react";
import { Grid, Typography, Button, Card, CardHeader, makeStyles } from "@material-ui/core";
import { Avatar, Markdown, MarkdownEditor } from "../misc";
import { useUserContext } from "../auth/UserContextProvider";
import { BeaconStoryComment } from "../../../api/graphQL/queryModels";
import { Formatter } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2)
    }
}));

interface StoryCommentsProps {
    comments: BeaconStoryComment[] | null;
    saveComment: (comment: string) => Promise<unknown>;
}

const StoryComments = (props: StoryCommentsProps) => {
    const { user: currentUser } = useUserContext();

    const [draftComment, setDraftComment] = React.useState("");
    const [isSavingComment, setIsSavingComment] = React.useState(false);

    const saveComment = async () => {
        setIsSavingComment(true);
        await props.saveComment(draftComment);
        setIsSavingComment(false);
        setDraftComment("");
    };

    const classes = useStyles();

    const onDraftTextChange = (value: string) => {
        setDraftComment(value);
    };

    const { comments } = props;
    const currentUserInitials = (currentUser?.person.givenName || "?").substr(0, 1) + (currentUser?.person.surname || "?").substr(0, 1);

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h5">Comments</Typography>
            </div>
            <Grid container spacing={2}>
                {comments && comments.length ? (
                    comments.map((c) => {
                        const initials = (c.author.givenName || "?").substr(0, 1) + (c.author.surname || "?").substr(0, 1);

                        return (
                            <Grid key={c.id} item xs={12}>
                                <Card elevation={0}>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                size="md"
                                                text={initials}
                                                image={c.author.photos.length ? c.author.photos[0].uri : "/img/no_photo.png"}
                                                isActive={true}
                                            />
                                        }
                                        title={<Markdown>{c.text}</Markdown>}
                                        subheader={Formatter.formatLocalDate(c.dateCreated)}
                                        style={{ alignItems: "center" }}
                                    />
                                </Card>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12}>
                        <Typography>Nothing yet!</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    size="md"
                                    text={currentUserInitials}
                                    image={currentUser?.person.photos.length ? currentUser?.person.photos[0].uri : undefined}
                                    isActive={true}
                                />
                            }
                            title={
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MarkdownEditor value={draftComment} onChange={onDraftTextChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={saveComment}
                                            disabled={draftComment.trim().length <= 0 || isSavingComment}
                                        >
                                            {isSavingComment ? "Saving..." : "Save Comment"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            style={{ alignItems: "start" }}
                        />
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default StoryComments;
