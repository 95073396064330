import * as React from "react";
import { Typography } from "@material-ui/core";
import { Page } from "./components/layout";
import { EditableTagList } from "./components/tags";
import { GraphQlConnection, BeaconItemTag } from "../api/graphQL/queryModels";
import { useQuery, useMutation } from "@apollo/client";
import { GET_BEACON_TAGS } from "../api/graphQL/queries";
import { UPSERT_BEACON_TAG } from "../api/graphQL/mutations";
import { useNotifications } from "./components/notifications/NotificationContextProvider";
import { useUserContext } from "./components/auth/UserContextProvider";
import DocumentTitle from "./components/DocumentTitle";

const TagEditorPage = () => {
    const { isContentAdmin } = useUserContext();
    const { setNotification } = useNotifications();

    const {
        data: tagsData,
        loading: isFetching,
        error: tagsError
    } = useQuery<{ beaconTags: GraphQlConnection<BeaconItemTag> }>(GET_BEACON_TAGS, {
        fetchPolicy: "cache-and-network"
    });

    const tags = tagsData?.beaconTags.edges.map((e) => e.node) || [];

    const [upsertTag, { error }] = useMutation<{ beaconTagUpsert: BeaconItemTag }>(UPSERT_BEACON_TAG, {
        refetchQueries: [{ query: GET_BEACON_TAGS }],
        onCompleted: () => {
            setNotification("Tag saved", "success");
        }
    });

    React.useEffect(() => {
        if (error?.message || tagsError?.message) {
            setNotification(error?.message || tagsError?.message, "error");
        }
    }, [error, tagsError, setNotification]);

    const handleSaveTag = async (tag: BeaconItemTag) => {
        const response = await upsertTag({
            variables: {
                beaconTag: {
                    id: tag.id,
                    name: tag.name,
                    isDeleted: tag.isDeleted
                }
            }
        });
        return response.data?.beaconTagUpsert ?? null;
    };

    return (
        <>
            <DocumentTitle pageTitle="Edit tags" />
            <Page className="dashboard-page">
                <Typography className="beacon-name" variant="h2" component="h2">
                    Tags
                </Typography>
                {tags && !!tags.length && isContentAdmin && <EditableTagList tags={tags} saveTag={handleSaveTag} />}
                {!tags && isFetching && <Typography>Fetching...</Typography>}
                {!isFetching && !isContentAdmin ? (
                    <div className="access-error">You do not have the authorization to access this page.</div>
                ) : null}
            </Page>
        </>
    );
};

export default TagEditorPage;
