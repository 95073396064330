import { TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { BeaconStoryDataQualityReport, FieldDataQuality, FieldDataQualitySummary } from "../../../api/graphQL/queryModels";
import DataQualityScoreIcon from "./DataQualityScoreIcon";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    avatar: {
        background: "#fff",
        "& > img": {
            width: 40,
            height: "auto"
        }
    },
    centered: {
        justifyItems: "center",
        alignItems: "center"
    },
    left: {
        justifyItems: "left",
        alignItems: "left"
    },
    right: {
        textAlign: "right",
        verticalAlign: "middle"
    },
    aligned: {
        display: "inline-flex",
        "& > *": {
            marginRight: theme.spacing(1)
        }
    },
    expansionCell: {
        paddingBottom: 0,
        paddingTop: 0
    },
    wrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        "& > a": {
            textDecoration: "none",
            color: "inherit"
        }
    }
}));

const StoryDataQualityTableRow: React.FunctionComponent<{
    report?: BeaconStoryDataQualityReport;
}> = (props) => {
    const classes = useStyles();
    const story = props.report?.beaconStory;

    const getFieldByName = (name: string): FieldDataQualitySummary | undefined => {
        return (props.report?.fields || []).find((f: FieldDataQuality) => f.fieldName.toLowerCase() === name.toLowerCase());
    };
    const fieldNames = [
        "title",
        "previewDescription",
        "description",
        "project",
        "project successes",
        "project failures",
        "project improvements",
        "header image",
        "images",
        "items"
    ];

    return (
        <TableRow className={classes.root}>
            <TableCell>
                <span className={clsx(classes.wrapper, classes.left)}>
                    <Typography component={RouterLink} to={`/stories/${story?.id}`}>
                        {story ? story.title : <Skeleton variant="text" width={140} />}
                    </Typography>
                </span>
            </TableCell>
            <TableCell />
            <TableCell>
                <span className={clsx(classes.wrapper, classes.left)}>
                    <Typography>{props.report ? props.report?.overallScore : <Skeleton variant="text" width={20} />}</Typography>
                </span>
            </TableCell>
            {fieldNames.map((fieldName) => {
                const fieldQualityScore = getFieldByName(fieldName);
                return (
                    <TableCell key="TableCell 1">
                        <span className={clsx(classes.wrapper, classes.centered)}>
                            <DataQualityScoreIcon score={fieldQualityScore?.score} violations={fieldQualityScore?.violations} />
                        </span>
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default StoryDataQualityTableRow;
