import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardActionArea, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BeaconContentSearchResult } from "../../../api/graphQL/queryModels";

const useStyles = makeStyles((theme) => ({
    highlightedSearchResult: {
        "& em": {
            fontWeight: 800
        }
    },
    chips: {
        "& > *": {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        }
    },
    clamped2: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    }
}));

interface SearchResultListItemProps {
    result: BeaconContentSearchResult;
    onClick: () => unknown;
}

const SearchResultListItem = (props: SearchResultListItemProps) => {
    const classes = useStyles();
    const { result } = props;

    let url = "#";
    switch (result.type) {
        case "Item":
            url = `/items/${result.id}`;
            break;
        case "Story":
            url = `/stories/${result.id}`;
            break;
    }
    const highlights: { [key: string]: string[] } = {};
    for (const highlight of result.highlights) {
        highlights[highlight.matchingFieldName] = highlight.matchingTextFragments;
    }
    return (
        <Card elevation={0}>
            <CardActionArea component={RouterLink} to={url} onClick={props.onClick}>
                <CardContent className="">
                    <Typography
                        variant="h5"
                        component="h5"
                        color={result.type === "Story" ? "primary" : "inherit"}
                        className={classes.highlightedSearchResult}
                    >
                        {result.highlights && highlights["title"] ? (
                            <span dangerouslySetInnerHTML={{ __html: highlights["title"][0] }}></span>
                        ) : (
                            result.title
                        )}
                    </Typography>

                    {!result.highlights || (!highlights["previewDescription"] && !highlights["description"]) ? (
                        <Typography variant="body2" color="textPrimary" component="p" className={classes.clamped2}>
                            {result.previewDescription}
                        </Typography>
                    ) : null}

                    <div className={classes.highlightedSearchResult}>
                        {result.highlights
                            ? Object.keys(highlights)
                                  .filter((k) => k !== "title")
                                  .map((k) => {
                                      const matches = highlights[k];
                                      return (
                                          <>
                                              <Typography variant="subtitle2">
                                                  <span dangerouslySetInnerHTML={{ __html: matches[0] }}></span>
                                              </Typography>
                                          </>
                                      );
                                  })
                                  .find(() => true) // just show the first highlight
                            : null}
                    </div>
                    {!!result.storyLinkedItemNames && !!result.storyLinkedItemNames.length && (
                        <div className={classes.chips}>
                            {result.storyLinkedItemNames.map((i) => {
                                return <Chip key={i} label={i} variant="outlined" />;
                            })}
                        </div>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default SearchResultListItem;
