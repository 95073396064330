import * as React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { BeaconStory } from "../../../api/graphQL/queryModels";
import { useQuery, useMutation } from "@apollo/client";
import { GET_BEACON_STORY } from "../../../api/graphQL/queries";
import { useUserContext } from "../auth/UserContextProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import { BeaconStoryInput } from "../../../api/graphQL/mutationModels";
import { useNotifications } from "../notifications/NotificationContextProvider";
import { UPSERT_BEACON_STORY } from "../../../api/graphQL/mutations";

interface StoryDetailsProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StoryArchive = (props: StoryDetailsProps) => {
    const { isOpen, setIsOpen } = props;
    const { setNotification } = useNotifications();
    const { user: currentUser, isContentAdmin } = useUserContext();
    const { id } = useParams<{ id: string }>();

    const { data } = useQuery<{ beaconStory: BeaconStory }>(GET_BEACON_STORY, {
        skip: !id,
        variables: {
            id
        }
    });

    const story = data?.beaconStory;
    const archiveValue = !story?.isArchived;
    const canEdit = (!!currentUser?.id && story?.author?.id === currentUser.id) || isContentAdmin;

    const [upsertBeaconStory] = useMutation<{ beaconStoryUpsert: BeaconStoryInput }>(UPSERT_BEACON_STORY);

    const handleSave = () => {
        setIsOpen(false);
        saveItem(storyInput);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const storyInput = story
        ? ({
              id: story.id,
              title: story.title,
              author: {
                  id: story.author?.id || currentUser?.id
              },
              dateCreated: story.dateCreated,
              datePublished: story.datePublished,
              isPublished: story.isPublished,
              isArchived: archiveValue,
              projectId: story.project?.id,
              projectStatus: story.projectStatus,
              description: story.description,
              images: story.images.map((i) => ({
                  title: i.title,
                  description: i.description,
                  filename: i.filename,
                  url: i.url,
                  optimizedFilename: i.optimizedFilename,
                  optimizedUrl: i.optimizedUrl,
                  thumbnailFilename: i.thumbnailFilename,
                  thumbnailUrl: i.thumbnailUrl,
                  showInGallery: i.showInGallery,
                  showInHeader: i.showInHeader
              })),
              outcome: {
                  successNotes: story.outcome.successNotes,
                  failureNotes: story.outcome.failureNotes,
                  improvementNotes: story.outcome.improvementNotes,
                  difficultySentiment: story.outcome.difficultySentiment,
                  clientSentiment: story.outcome.clientSentiment,
                  accomplishmentSentiment: story.outcome.accomplishmentSentiment,
                  teamCohesivenessSentiment: story.outcome.teamCohesivenessSentiment,
                  stressLevelSentiment: story.outcome.stressLevelSentiment
              },
              previewDescription: story.previewDescription,
              selectedItems: story.selectedItems.map((i) => ({
                  title: i.title,
                  explanation: i.explanation,
                  selectedItemId: i.selectedItem.id,
                  outcome: {
                      justification: i.outcome.justification,
                      sentiment: i.outcome.sentiment
                  },
                  consideredAlternativeItemIds: i.consideredAlternativeItems.map((j) => j.id)
              }))
          } as BeaconStoryInput)
        : null;

    const saveItem = async (beaconStory: BeaconStoryInput | null) => {
        {
            !story?.isArchived ? setNotification("Story Archived", "success") : setNotification("Story Unarchived", "success");
        }

        await upsertBeaconStory({
            variables: {
                beaconStory
            }
        });
    };

    return (
        <div>
            <Dialog open={isOpen} aria-labelledby="alert-dialog-title">
                <DialogTitle id="dialog-title">{!story?.isArchived ? "Archive" : "Unarchive"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        {!story?.isArchived
                            ? "Are you sure you want to archive this story?"
                            : "Are you sure you want to unarchive this story"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {canEdit ? (
                        <Button onClick={handleClose} variant="outlined">
                            No, go back
                        </Button>
                    ) : null}
                    <Button onClick={handleSave} variant="contained" component={RouterLink} to={`/?view=stories`}>
                        {!story?.isArchived ? "Yes, archive" : "Yes, Unarchive"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default StoryArchive;
