import * as React from "react";
import { ListItem, TextField, Button } from "@material-ui/core";

import { BeaconItemTag } from "../../../api/graphQL/queryModels";

interface EditableTagListProps {
    tag: BeaconItemTag;
    isNewTag?: boolean;
    saveTag: (tag: BeaconItemTag) => Promise<BeaconItemTag | null>;
}

const EditableTagListItem = (props: EditableTagListProps) => {
    const { tag, isNewTag = false } = props;

    const [workingValue, setWorkingValue] = React.useState(tag.name);
    const [isDirty, setIsDirty] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onNameChange = (tag: BeaconItemTag) => (e: any) => {
        const value = e.target.value;
        setWorkingValue(value);

        if (!isDirty && value !== tag.name) {
            setIsDirty(true);
        } else if (isDirty && value === tag.name) {
            setIsDirty(false);
        }
    };

    const saveTag = async () => {
        setIsSaving(true);
        const savedValue = await props.saveTag({
            ...tag,
            name: workingValue
        });

        setIsSaving(false);
        if (!!savedValue && savedValue.name === workingValue) {
            setIsDirty(false);
            setWorkingValue(tag.name);
        }
    };

    return (
        <ListItem key={tag.id}>
            <TextField value={workingValue} onChange={onNameChange(tag)} placeholder={isNewTag ? "New tag" : undefined} />
            {(isDirty || isSaving) && (
                <Button disabled={isSaving || !isDirty} onClick={saveTag}>
                    {isSaving ? "Saving..." : "Save"}
                </Button>
            )}
        </ListItem>
    );
};

export default EditableTagListItem;
