import * as React from "react";
import { Button } from "@material-ui/core";
import { FormControl, FormField, FormSection } from "../forms";
import StoryEditorImageList from "./StoryEditorImageList";
import { BeaconStoryImageMetadataInput } from "../../../api/graphQL/mutationModels";
import { useApolloClient } from "@apollo/client";
import { GET_BEACON_IMAGE_UPLOAD_TOKEN } from "../../../api/graphQL/queries";
import { BeaconImageUploadToken } from "../../../api/graphQL/queryModels";
import { BlockBlobClient } from "@azure/storage-blob";
interface StoryEditorImagesSectionProps {
    value: BeaconStoryImageMetadataInput[];
    onChange: (value: BeaconStoryImageMetadataInput[]) => unknown;
}

const StoryEditorImagesSection = (props: StoryEditorImagesSectionProps) => {
    const client = useApolloClient();

    const onImagePropChange =
        (filename: string) => (propNames: (keyof BeaconStoryImageMetadataInput)[], value: Partial<BeaconStoryImageMetadataInput>) => {
            const newImages = props.value.map((i) => {
                if (i.filename === filename) {
                    const newImage = {
                        ...i
                    } as BeaconStoryImageMetadataInput;
                    for (const propName of propNames) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (newImage as any)[propName] = value[propName];
                    }
                    return newImage;
                }
                return i;
            });
            props.onChange(newImages);
        };

    const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const response = await client.query<{ beaconImageUploadToken: BeaconImageUploadToken }>({
                query: GET_BEACON_IMAGE_UPLOAD_TOKEN,
                variables: {
                    filename: file.name
                }
            });
            if (response.data) {
                const uploadToken = response.data.beaconImageUploadToken;
                const newImage = {
                    filename: uploadToken.uniqueFilename,
                    url: uploadToken.fileUrl,
                    optimizedFilename: null,
                    thumbnailFilename: null,
                    title: "",
                    description: "",
                    showInGallery: false,
                    showInHeader: false
                } as BeaconStoryImageMetadataInput;

                const blobClient = new BlockBlobClient(uploadToken.fileUrl + uploadToken.sasToken);
                try {
                    await blobClient.uploadData(file, {
                        onProgress: () => {
                            // setUploadPercentComplete((progress.loadedBytes / file!.size) * 100);
                        }
                    });
                    props.onChange(props.value.concat(newImage));
                } catch (err) {
                    // handle
                }
            }
        }
    };

    const onDeleteImageRequested = (filename: string) => {
        const newImages = props.value.filter((p) => p.filename !== filename);
        props.onChange(newImages);
    };

    return (
        <FormSection title="Images" fullWidth>
            <FormField fullWidth>
                <FormControl>
                    <StoryEditorImageList
                        images={props.value}
                        onImageDeleteRequested={onDeleteImageRequested}
                        onImagePropChange={onImagePropChange}
                    />
                    <Button className="attach-file-btn" variant="outlined" component="label">
                        <i className="material-icons">file_upload</i>
                        <span>Add image</span>
                        <input id={"add_image"} onChange={onFileSelected} style={{ display: "none" }} type="file" />
                    </Button>
                </FormControl>
            </FormField>
        </FormSection>
    );
};

export default StoryEditorImagesSection;
