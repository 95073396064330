import * as React from "react";
import { BeaconItemDifficulty } from "../../../api/graphQL/queryModels";

interface DifficultyProps {
    difficulty: BeaconItemDifficulty;
}

const Difficulty = (props: DifficultyProps) => {
    const { difficulty } = props;
    const text = difficulty === BeaconItemDifficulty.None ? "None" : difficulty.toString();

    return <span>{text}</span>;
};

export default Difficulty;
