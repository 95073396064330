import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, makeStyles } from "@material-ui/core";
import { BeaconItem } from "../../../api/graphQL/queryModels";

interface SimpleBeaconListItemProps {
    beacon: BeaconItem;
    selected?: boolean;
}

const useStyles = makeStyles((theme) => ({
    beaconListItem: {
        color: theme.palette.text.accent,
        textDecoration: "underline",
        "&.selected": {
            fontSize: "1.5rem"
        },
        "&.considered": {
            fontSize: "1rem"
        },
        "&.avoid": {
            color: theme.palette.error.main
        }
    }
}));

const SimpleBeaconListItem = (props: SimpleBeaconListItemProps) => {
    const { beacon, selected } = props;
    const classes = useStyles();

    return (
        <Link
            component={RouterLink}
            to={`/items/${beacon.id}`}
            className={`${classes.beaconListItem} ${beacon.endorsement?.toLowerCase()} ${selected ? "selected" : "considered"}`}
        >
            {beacon.name}
        </Link>
    );
};

export default SimpleBeaconListItem;
