import * as React from "react";
import { Grid } from "@material-ui/core";
import StoryOutcomeSentimentBadge from "./StoryOutcomeSentimentBadge";
import { BeaconStoryOutcome } from "../../../api/graphQL/queryModels";

interface StoryOutcomeSentimentBadgeListProps {
    outcome: BeaconStoryOutcome;
}

const StoryOutcomeSentimentBadgeList = (props: StoryOutcomeSentimentBadgeListProps) => {
    const { outcome } = props;
    return (
        <Grid container spacing={2}>
            <Grid item>
                <StoryOutcomeSentimentBadge
                    title="Difficulty"
                    sentimentPropertyName="difficultySentiment"
                    sentimentValue={outcome.difficultySentiment}
                />
            </Grid>
            <Grid item>
                <StoryOutcomeSentimentBadge
                    title="Client happiness"
                    sentimentPropertyName="clientSentiment"
                    sentimentValue={outcome.clientSentiment}
                />
            </Grid>
            <Grid item>
                <StoryOutcomeSentimentBadge
                    title="Team satisfaction"
                    sentimentPropertyName="accomplishmentSentiment"
                    sentimentValue={outcome.accomplishmentSentiment}
                />
            </Grid>
            <Grid item>
                <StoryOutcomeSentimentBadge
                    title="Team collaboration"
                    sentimentPropertyName="teamCohesivenessSentiment"
                    sentimentValue={outcome.teamCohesivenessSentiment}
                />
            </Grid>
            <Grid item>
                <StoryOutcomeSentimentBadge
                    title="Stress levels"
                    sentimentPropertyName="stressLevelSentiment"
                    sentimentValue={outcome.stressLevelSentiment}
                />
            </Grid>
        </Grid>
    );
};

export default StoryOutcomeSentimentBadgeList;
