import * as React from "react";
import { Grid } from "@material-ui/core";

import StoryImageListItem from "./StoryEditorImageListItem";
import { BeaconStoryImageMetadataInput } from "../../../api/graphQL/mutationModels";

interface StoryEditorImageListProps {
    images: BeaconStoryImageMetadataInput[];
    onImagePropChange: (
        filename: string
    ) => (propNames: (keyof BeaconStoryImageMetadataInput)[], value: Partial<BeaconStoryImageMetadataInput>) => unknown;
    onImageDeleteRequested: (filename: string) => unknown;
}

const StoryEditorImageList = (props: StoryEditorImageListProps) => {
    const { images } = props;

    const onAttachmentDeleteRequested = (filename: string) => () => {
        props.onImageDeleteRequested(filename);
    };

    return (
        <Grid container spacing={2} className="working-attachment-preview-list">
            {images.map((image, i) => {
                return (
                    <Grid item xs={12} sm={6} md={6} key={image.filename || i}>
                        <StoryImageListItem
                            image={image}
                            onDeleteRequested={onAttachmentDeleteRequested(image.filename)}
                            onImagePropChange={props.onImagePropChange(image.filename)}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default StoryEditorImageList;
