import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ButtonGroup, Box, Button, Fade, Typography, Grid, Card, CardMedia, Tabs, Tab, Link } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import queryString from "query-string";
import { SearchBait, SearchDialog } from "./components/search";
import { BeaconCreateButton, ItemList } from "./components/items";
import { Page } from "./components/layout";
import { StoryList } from "./components/stories";
import { TagFilter } from "./components/tags";
import { useQuery } from "@apollo/client";
import { GraphQlConnection, BeaconItemTag, BeaconStory, BeaconItem, BeaconItemCategory } from "../api/graphQL/queryModels";
import { GET_BEACON_TAGS, GET_BEACON_STORIES, GET_BEACON_ITEMS } from "../api/graphQL/queries";
import DocumentTitle from "./components/DocumentTitle";
import { Waypoint } from "react-waypoint";

const useStyles = makeStyles((theme) => ({
    flexDisplay: {
        display: "flex"
    },
    actionHeader: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2)
    },
    tabs: {
        "& .MuiTab-root": {
            fontSize: "1.25rem"
        }
    },
    dataQualityLink: {
        color: theme.palette.text.accent,
        textDecoration: "underline"
    }
}));

const pageSize = 20;

const DashboardPage = () => {
    const classes = useStyles();

    const location = useLocation();

    const queryParams = queryString.parse((location.search || "?").substr(1));

    const { data: tagData } = useQuery<{ beaconTags: GraphQlConnection<BeaconItemTag> }>(GET_BEACON_TAGS);
    const tags = tagData?.beaconTags.edges.map((e) => e.node) || [];

    const [searchOpen, setSearchOpen] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");

    const {
        data: storiesData,
        loading: isFetchingStories,
        fetchMore: fetchMoreStories
    } = useQuery<{
        beaconStories: GraphQlConnection<BeaconStory>;
    }>(GET_BEACON_STORIES, {
        variables: {
            first: pageSize,
            after: null
        },
        notifyOnNetworkStatusChange: true
    });

    const handleLoadMoreStories = () => {
        fetchMoreStories({
            variables: {
                first: pageSize,
                after: storiesData?.beaconStories?.pageInfo.endCursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.beaconStories.edges;

                return newEdges?.length
                    ? {
                          // Put the new items at the end of the list and update `pageInfo`
                          // so we have the new `endCursor` and `hasNextPage` values
                          beaconStories: {
                              __typename: previousResult.beaconStories?.__typename,
                              edges: [...previousResult.beaconStories.edges, ...newEdges],
                              pageInfo: fetchMoreResult.beaconStories.pageInfo
                          }
                      }
                    : previousResult;
            }
        });
    };

    const stories = storiesData?.beaconStories.edges.map((e) => e.node) || [];

    const [selectedTags, setSelectedTags] = React.useState<BeaconItemTag[]>([]);
    const view = queryParams.view || "stories";

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const selectedCategory = queryParams.category || BeaconItemCategory.None;

    const {
        data: itemsData,
        loading: isFetchingItems,
        fetchMore: fetchMoreItems
    } = useQuery<{
        beaconItems: GraphQlConnection<BeaconItem>;
    }>(GET_BEACON_ITEMS, {
        variables: {
            first: pageSize,
            after: null,
            categories: selectedCategory === BeaconItemCategory.None ? [] : [selectedCategory],
            tagIds: selectedTags.map((t) => t.id)
        },
        notifyOnNetworkStatusChange: true
    });

    const handleLoadMoreItems = () => {
        fetchMoreItems({
            variables: {
                first: pageSize,
                after: itemsData?.beaconItems?.pageInfo.endCursor,
                categories: selectedCategory === BeaconItemCategory.None ? [] : [selectedCategory],
                tagIds: selectedTags.map((t) => t.id)
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.beaconItems.edges;

                return newEdges?.length
                    ? {
                          // Put the new items at the end of the list and update `pageInfo`
                          // so we have the new `endCursor` and `hasNextPage` values
                          beaconItems: {
                              __typename: previousResult.beaconItems?.__typename,
                              edges: [...previousResult.beaconItems.edges, ...newEdges],
                              pageInfo: fetchMoreResult.beaconItems.pageInfo
                          }
                      }
                    : previousResult;
            }
        });
    };

    const items = itemsData?.beaconItems.edges.map((e) => e.node) || [];

    const pageTitle = view === "stories" ? "Stories" : "Items";

    return (
        <>
            <DocumentTitle pageTitle={pageTitle} />
            <Page noBackground noSidePadding className="dashboard-page">
                <div className="dashboard-page-links">
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item lg={3} md={4}>
                            <Tabs value={view} className={classes.tabs}>
                                <Tab label="Stories" value="stories" component={RouterLink} to="/?view=stories" />
                                <Tab label="Items" value="items" component={RouterLink} to="/?view=items" />
                            </Tabs>
                        </Grid>
                        <Grid item lg={7} md={4}>
                            <SearchBait searchText={searchText} onClick={() => setSearchOpen(true)} />
                            <SearchDialog
                                open={searchOpen}
                                searchText={searchText}
                                onTextChange={(value: string) => setSearchText(value)}
                                onClose={() => setSearchOpen(false)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dataQualityLink} variant="body1">
                                <Link color="inherit" component={RouterLink} to={`/data-quality`}>
                                    Beacon data quality
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={7}>
                    <Grid item xs={12}>
                        <div className="dashboard-page-content">
                            {view === "stories" ? (
                                <Fade in>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} container className="action-header" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h4">Stories</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="story-create-btn"
                                                    component={RouterLink}
                                                    to={`/stories/new`}
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                >
                                                    New Story
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StoryList stories={stories || []} />
                                            {isFetchingStories && (
                                                <Grid container spacing={4}>
                                                    {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                        <Grid key={i} item xs={12} sm={6} lg={4}>
                                                            <Card className="story-placeholder">
                                                                <CardMedia>
                                                                    <Skeleton variant="rect" width="100%" height={100} />
                                                                </CardMedia>
                                                                <Skeleton variant="text" width={300} height={18} />
                                                                <Skeleton variant="text" width={"100%"} height={12} />
                                                                <Skeleton variant="text" width={"100%"} height={12} />
                                                                <Skeleton variant="text" width={200} height={12} />
                                                                <Skeleton variant="circle" width={32} height={32} />
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}
                                            {!isFetchingStories &&
                                                !!storiesData?.beaconStories?.pageInfo?.hasNextPage &&
                                                !!storiesData?.beaconStories.pageInfo?.endCursor && (
                                                    <Waypoint
                                                        key={storiesData.beaconStories.edges.length}
                                                        onEnter={handleLoadMoreStories}
                                                    />
                                                )}
                                        </Grid>
                                    </Grid>
                                </Fade>
                            ) : null}
                            {view === "items" ? (
                                <Fade in>
                                    <div>
                                        <Box mb={2}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography gutterBottom variant="h4">
                                                        Items
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <BeaconCreateButton />
                                                </Grid>
                                                <Grid item container spacing={2}>
                                                    <Grid item>
                                                        <ButtonGroup size="small" orientation="horizontal">
                                                            <Button
                                                                component={RouterLink}
                                                                to="/?view=items"
                                                                variant={
                                                                    selectedCategory === BeaconItemCategory.None ? "contained" : "outlined"
                                                                }
                                                                disableElevation
                                                            >
                                                                All
                                                            </Button>
                                                            <Button
                                                                component={RouterLink}
                                                                to="/?view=items&category=METHODOLOGY"
                                                                variant={
                                                                    selectedCategory === BeaconItemCategory.Methodology
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                disableElevation
                                                            >
                                                                Methodologies
                                                            </Button>
                                                            <Button
                                                                component={RouterLink}
                                                                to="/?view=items&category=TECHNIQUE"
                                                                variant={
                                                                    selectedCategory === BeaconItemCategory.Technique
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                disableElevation
                                                            >
                                                                Techniques
                                                            </Button>
                                                            <Button
                                                                component={RouterLink}
                                                                to="/?view=items&category=TOOL"
                                                                variant={
                                                                    selectedCategory === BeaconItemCategory.Tool ? "contained" : "outlined"
                                                                }
                                                                disableElevation
                                                            >
                                                                Tools
                                                            </Button>
                                                            <Button
                                                                component={RouterLink}
                                                                to="/?view=items&category=TECHNOLOGY"
                                                                variant={
                                                                    selectedCategory === BeaconItemCategory.Technology
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                disableElevation
                                                            >
                                                                Technologies
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                    <Grid item>
                                                        <TagFilter tags={tags || []} onChange={(value) => setSelectedTags(value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <ItemList items={items || []} loading={isFetchingItems} />
                                        {isFetchingItems && (
                                            <Grid container spacing={4}>
                                                {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                    <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                                                        <Card className="story-placeholder" style={{ height: 200 }}>
                                                            <Skeleton variant="text" height={18} />
                                                            <Skeleton variant="text" width={"100%"} height={12} />
                                                            <Skeleton variant="text" width={"100%"} height={12} />
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                        {!isFetchingItems &&
                                            !!itemsData?.beaconItems?.pageInfo?.hasNextPage &&
                                            !!itemsData?.beaconItems.pageInfo?.endCursor && (
                                                <Waypoint key={itemsData.beaconItems.edges.length} onEnter={handleLoadMoreItems} />
                                            )}
                                    </div>
                                </Fade>
                            ) : null}
                        </div>
                    </Grid>
                </Grid>
            </Page>
        </>
    );
};

export default DashboardPage;
